// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topic-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 20px;
  height: 250px;
  justify-content: center;
}

.topic-first-row {
  display: flex;
  align-items: center;
  justify-content: start;
  /* width: 100%; */
  /* margin: 0 100px; */
  gap: 12px;
  flex-direction: column;
  flex: 1 1;
}

.topic-box {
  display: flex;
  height: 51px;
  padding: 0px 8px;
  align-items: center;
  border-radius: 8px;
  justify-content: space-between;
  border: 1px solid var(--primaryTransparent);
  background: transparent;
  width: 500px;
  padding: 5px 10px;
}
.topic-box-span {
  color: var(--gray100);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 50px);
}

.topic-box:hover {
  background: rgba(168, 199, 250, 0.25);
}

.textbtn-span {
  color: var(--primary);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/Components/dashboard/topicLevel/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;EACjB,qBAAqB;EACrB,SAAS;EACT,sBAAsB;EACtB,SAAO;AACT;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,8BAA8B;EAC9B,2CAA2C;EAC3C,uBAAuB;EACvB,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".topic-container {\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  width: 100%;\n  gap: 20px;\n  height: 250px;\n  justify-content: center;\n}\n\n.topic-first-row {\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  /* width: 100%; */\n  /* margin: 0 100px; */\n  gap: 12px;\n  flex-direction: column;\n  flex: 1;\n}\n\n.topic-box {\n  display: flex;\n  height: 51px;\n  padding: 0px 8px;\n  align-items: center;\n  border-radius: 8px;\n  justify-content: space-between;\n  border: 1px solid var(--primaryTransparent);\n  background: transparent;\n  width: 500px;\n  padding: 5px 10px;\n}\n.topic-box-span {\n  color: var(--gray100);\n  font-family: Inter;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.36px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: calc(100% - 50px);\n}\n\n.topic-box:hover {\n  background: rgba(168, 199, 250, 0.25);\n}\n\n.textbtn-span {\n  color: var(--primary);\n  text-align: center;\n  font-family: Inter;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
