import React, { useEffect, useState } from "react";
import "./style.css";
import CheckBox from "../../checkbox";
import RadioButton from "../../radioButton";
import DashboardHeader, { SocialEnum } from "../header";
import { Level } from "../dashboard";
import axios from "axios";
import { setSnackMessage, SnackType } from "../../../reducer/snackbar";
import { useDispatch } from "react-redux";
import CircularIndeterminate from "../../circularIndeterminate";
import ScheduleModal from "../scheduleModal";
import SnackBar from "../../snackBar";
import { api } from "../../../api";
import { useNavigate } from "react-router-dom";

export const TranslateLanguage = {
  language: "",
  caption: "",
};

function Publish({ accessToken, setPostPublished, postsList, signout }) {
  const [selectedPost, setSelectedPost] = useState(null);
  const [key, setKey] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [selectedPlatform, setSelectedPlatrom] = useState(-1);
  const [menuList, setMenuList] = useState([]);

  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const englishSelect = postsList.find((l) => l.lang === "English");
    setSelectedPost(englishSelect);
    getUserChannels();
  }, []);

  const getUserChannels = () => {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .get("https://cfstagingapi.alo.hair/api/channels/own", {
    //     headers: headers,
    //   })
    api("get", "channels/own", {}, headers, navigate)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          const platfroms = res.data.map((aa) => aa.platform);
          const availablePlatform = new Set([...platfroms]);
          const userMenu = [];

          if (availablePlatform.has("INSTAGRAM"))
            userMenu.push({ id: SocialEnum.Instagram, text: "Instagram" });
          if (availablePlatform.has("TELEGRAM"))
            userMenu.push({ id: SocialEnum.Telegram, text: "Telegram" });
          if (availablePlatform.has("WHATSAPP"))
            userMenu.push({ id: SocialEnum.WhatsApp, text: "WhatsApp" });
          if (availablePlatform.has("LINKEDIN"))
            userMenu.push({ id: SocialEnum.Linkedin, text: "Linkedin" });

          setMenuList(userMenu);

          const channels = [];
          res.data.map((r) => {
            channels.push({
              id: r.id,
              text: r.channel_address,
              platfrom:
                r.platform === "TELEGRAM"
                  ? SocialEnum.Telegram
                  : r.platform === "LINKEDIN"
                  ? SocialEnum.Linkedin
                  : r.platform === "INSTAGRAM"
                  ? SocialEnum.Instagram
                  : SocialEnum.WhatsApp,
            });
          });
          setChannelList(channels);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
        }
      });
  };

  const publishPost = (postId, channnelId) => {
    setShowProgress(true);
    const body = {
      channel_id: channnelId,
      post_id: postId,
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .post(
    //     "https://cfstagingapi.alo.hair/api/telegram/message",
    //     { ...body },
    //     {
    //       headers: headers,
    //     }
    //   )
    api("post", "telegram/message", body, headers, navigate)
      .then((res) => {
        setShowProgress(false);
        if (res.ok) {
          const channelName = channelList.find(
            (ch) => ch.id === +channnelId
          ).text;
          reduxDispatch(
            setSnackMessage({
              snak_message: `Your post publish on ${channelName}`,
              snack_type: SnackType.Success,
            })
          );
        } else {
          if (res.result.description)
            reduxDispatch(
              setSnackMessage({
                snak_message: res.result.description,
                snack_type: SnackType.Danger,
              })
            );
        }
        setKey((k) => k + 1);
      })
      .catch((e) => {
        setShowProgress(false);
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
        }
      });
  };

  const schedulePost = (postId, channnelId, schaduleTime) => {
    setShowProgress(true);
    const body = {
      channel_id: channnelId,
      post_id: postId,
      scheduled_datetime: schaduleTime,
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .post(
    //     "https://cfstagingapi.alo.hair/api/schedule",
    //     { ...body },
    //     {
    //       headers: headers,
    //     }
    //   )
    api("post", "schedule", body, headers, navigate)
      .then(() => {
        setShowProgress(false);
        setPostPublished(true);
        var date = new Date(schaduleTime);
        const channelName = channelList.find(
          (ch) => ch.id === +channnelId
        ).text;
        reduxDispatch(
          setSnackMessage({
            snak_message: `Your post will publish ${
              channelName ? `on ` + channelName : ""
            } at ${date.toLocaleString()}`,
            snack_type: SnackType.Success,
          })
        );
        setKey((k) => k + 1);
      })
      .catch((e) => {
        setShowProgress(false);
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
        }
      });
  };

  const publishPostOnLikedin = (postId, channelId) => {
    setShowProgress(true);
    const body = { channel_id: channelId, post_id: postId };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .post(
    //     `https://cfstagingapi.alo.hair/api/linkedin/send?post_id=${postId}`,
    //     { ...body },
    //     {
    //       headers: headers,
    //     }
    //   )
    api("post", `linkedin/send`, body, headers, navigate)
      .then(() => {
        setShowProgress(false);
        setPostPublished(true);
        reduxDispatch(
          setSnackMessage({
            snak_message: `Your post published on your linkedin`,
            snack_type: SnackType.Success,
          })
        );
        setKey((k) => k + 1);
      })
      .catch((e) => {
        setShowProgress(false);
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
        }
      });
  };

  const publishPostOnInstagram = (postId, channelId) => {
    setShowProgress(true);
    const body = { channel_id: channelId, post_id: postId };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    api("post", `instagram/send`, body, headers, navigate)
      .then(() => {
        setShowProgress(false);
        setPostPublished(true);
        reduxDispatch(
          setSnackMessage({
            snak_message: `Your post published on your instagram`,
            snack_type: SnackType.Success,
          })
        );
        setKey((k) => k + 1);
      })
      .catch((e) => {
        setShowProgress(false);
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e?.response?.data?.detail ?? "something went wrong",
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
        }
      });
  };

  return (
    <div
      style={{
        width: "90%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <SnackBar key={key} />
      {showProgress && <CircularIndeterminate />}
      {showModal && (
        <ScheduleModal
          channelList={channelList.filter(
            (ch) => ch.platfrom === selectedPlatform
          )}
          open={showModal}
          handleClose={() => setShowModal(false)}
          postsList={postsList}
          publishPost={(postId, channelId) => {
            // console.log(postId);
            // console.log(channelId);
            if (selectedPlatform === SocialEnum.Telegram)
              publishPost(postId, channelId);
            else if (selectedPlatform === SocialEnum.Linkedin) {
              const chId = channelList.find(
                (ch) => ch.platfrom === SocialEnum.Linkedin
              )?.id;
              publishPostOnLikedin(postId, chId);
            } else if (selectedPlatform === SocialEnum.Instagram) {
              const chId = channelList.find(
                (ch) => ch.platfrom === SocialEnum.Instagram
              )?.id;
              publishPostOnInstagram(postId, chId);
            }
          }}
          schedulePost={(postId, channelId, schaduleTime) => {
            // console.log(postId);
            // console.log(channelId);
            // console.log(schaduleTime);
            // console.log(selectedPlatform);
            // console.log(channelList);
            if (selectedPlatform === SocialEnum.Telegram)
              schedulePost(postId, channelId, schaduleTime);
            else if (selectedPlatform === SocialEnum.Linkedin) {
              const chId = channelList.find(
                (ch) => ch.platfrom === SocialEnum.Linkedin
              )?.id;
              if (chId) schedulePost(postId, chId, schaduleTime);
            } else if (selectedPlatform === SocialEnum.Instagram) {
              const chId = channelList.find(
                (ch) => ch.platfrom === SocialEnum.Instagram
              )?.id;
              if (chId) schedulePost(postId, chId, schaduleTime);
            }
          }}
          onFinish={() => setPostPublished(true)}
          platform={selectedPlatform}
        />
      )}

      <DashboardHeader
        level={Level.Publish}
        platformSelect={(id) => {
          setSelectedPlatrom(id);
          setShowModal(true);
        }}
        menuList={menuList}
      />
      <div className="publish-container">
        <div className="publish-top-div">
          <span>Language of Caption:</span>
          <div>
            {postsList.map((n) => {
              return (
                <div
                  className="publish-lang-div"
                  style={{
                    background: n.isChecked
                      ? "rgba(168, 199, 250, 0.25)"
                      : "transparent",
                  }}
                >
                  <input
                    type="radio"
                    value={n.postId}
                    id={n.postId}
                    name="radio"
                    onClick={() => {
                      setSelectedPost(n);
                    }}
                    className="input-hover"
                    defaultChecked={
                      selectedPost && selectedPost.postId === n.postId
                    }
                    style={{ width: "15px", height: "15px" }}
                  />
                  <label
                    style={{ fontSize: "18px" }}
                    className="radio-label"
                    htmlFor={n.postId}
                  >
                    {n.lang} Caption
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="publish-bottom-div">
          <div style={{ display: "flex", gap: "30px" }}>
            <span>Submit for video:</span>
            <RadioButton
              onChange={(e) => console.log(e)}
              labels={[
                { label: "Yes", id: "yes" },
                { label: "No", id: "no" },
              ]}
            />
          </div>
          <div className="publish-caption-div">
            <div
              style={{ flex: "1", height: "100%", background: "var(--white)" }}
            ></div>
            <div
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                gap: "15px",
                flex: "1",
              }}
            >
              <span
                style={{
                  color: "var(--gray600)",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.36px",
                }}
              >
                Caption
              </span>
              <div className="publish-caption">
                <span>{selectedPost && selectedPost.cap}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Publish;
