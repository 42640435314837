import React from "react";
import "./style.css";
// import CircularIndeterminate from "../circularIndeterminate";

function PrimaryButton({
  icon,
  disabledIcon,
  text,
  CTAIcon,
  width,
  isDisabled = false,
  onClick,
  style,
  className,
  height = "12px",
  // showProgress = false,
}) {
  const localClassName = isDisabled
    ? "primary-btn-disable"
    : "primary-btn-enable";
  // !showProgress && !isDisabled ? "primary-btn-enable" : "primary-btn-disable";
  return (
    <div
      style={{
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
      }}
      onClick={(e) => {
        // if (!isDisabled && !showProgress) onClick(e);
        if (!isDisabled) onClick(e);
      }}
    >
      <div
        style={{ ...style, width: "100%", height: "100%" }}
        className={`${className} ${localClassName}`}
      >
        {/* {showProgress && <CircularIndeterminate size="sm" />}
        {text && <span>{showProgress ? "Loading" : text}</span>}
        {icon && !showProgress && <img src={icon} />} */}
        {text && <span>{text}</span>}
        {icon && !isDisabled && <img src={icon} />}
        {disabledIcon && isDisabled && <img src={disabledIcon} />}
        {CTAIcon && <img src={CTAIcon} />}
      </div>
    </div>
  );
}

export default PrimaryButton;
