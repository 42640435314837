import React, { useState } from "react";
import "./style.css";

function Progress({ style, primaryProgressWidth, isSelect = false, title }) {
  const localClassName = isSelect ? "progress-activate" : "progress-deactive";
  return (
    <div className="progress-container" style={{ ...style }}>
      <span>{title}</span>
      <div className={localClassName}></div>
      <div className="progress-bar">
        <div
          className="progress-full"
          style={{ width: primaryProgressWidth }}
        ></div>
      </div>
    </div>
  );
}

export default Progress;
