import React, { useEffect, useState } from "react";
import "./App.css";
import LoginSignUpFix from "./Components/loginSignupFix";
import Dashboard from "./Components/dashboard/dashboard";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import LinkedIn from "./Components/linkedin";
// import InstagramRedirect from "./Components/instagramRedirect";

function App() {
  // const [isAuthenticated, setIsAuthenticated] = useState(true);

  // useEffect(() => {
  //   const session = localStorage.getItem("accessToken");
  //   if (session) setIsAuthenticated(true);
  // }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/linkedin" exact element={<LinkedIn />} />
          {/* <Route path="/instagram" exact element={<InstagramRedirect />} /> */}
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/loginnSignup" exact element={<LoginSignUpFix />} />

          <Route path="/" exact element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
