import React, { useEffect, useReducer, useState } from "react";
import Progress from "../../progress";
import InstagramIcon from "../../../img/instaIcon.svg";
import TelegramIcon from "../../../img/telegramIcon.svg";
import WhatsAppIcon from "../../../img/whatsappIcon.svg";
import LinkedinIcon from "../../../img/linkedinIcon.svg";

import "./style.css";
import PrimaryButton from "../../primaryButton";
import { Level } from "../dashboard";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    backgroundColor: "var(--gray800)",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const SocialEnum = {
  Instagram: 1,
  Telegram: 2,
  WhatsApp: 3,
  Linkedin: 4,
};

function DashboardHeader({
  level = Level.Topic,
  setSelectedChannelId,
  platformSelect,
  menuList = [],
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="dashboard-header-container">
        <span className="dashboard-header-topic">
          {level === Level.Topic
            ? "Topic of your Post"
            : level === Level.SocialReady
            ? "Social Ready"
            : level === Level.Translate
            ? "Translate"
            : "Publish"}
        </span>
        <div className="dashboard-progress-contaier">
          <Progress
            style={{ width: "68px" }}
            title="Topic"
            primaryProgressWidth={
              level === Level.Topic
                ? "50%"
                : level === Level.SocialReady ||
                  level === Level.Translate ||
                  level === Level.Publish
                ? "100%"
                : 0
            }
            isSelect={true}
          />

          <Progress
            style={{ width: "220px" }}
            title="Social Ready"
            primaryProgressWidth={
              level === Level.SocialReady
                ? "50%"
                : level === Level.Translate || level === Level.Publish
                ? "100%"
                : 0
            }
            isSelect={
              level === Level.SocialReady ||
              level === Level.Translate ||
              level === Level.Publish
            }
          />

          <Progress
            style={{ width: "68px" }}
            title="Translate"
            primaryProgressWidth={
              level === Level.Translate
                ? "50%"
                : level === Level.Publish
                ? "100%"
                : 0
            }
            isSelect={level === Level.Translate || level === Level.Publish}
          />

          <Progress
            style={{ width: "68px" }}
            title="Publish"
            primaryProgressWidth={
              level === Level.Publish
                ? "50%"
                : level === Level.Publish + 1
                ? "100%"
                : 0
            }
            isSelect={level === Level.Publish || level === Level.Publish + 1}
          />
        </div>
        {level === Level.Publish && (
          <PrimaryButton text="Publish" width="100px" onClick={handleClick} />
        )}
      </div>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuList.map((m) => {
          return (
            <div
              onClick={() => {
                // if (menuList.type === "social") {
                platformSelect(m.id);
                // } else {
                //   setSelectedChannelId(m.id);
                //   handleClose();
                // }
              }}
              className="dashboard-header-publish-menu-div"
              key={m.id}
            >
              <img
                src={
                  m.id === SocialEnum.Instagram
                    ? InstagramIcon
                    : m.id === SocialEnum.Telegram
                    ? TelegramIcon
                    : m.id === SocialEnum.WhatsApp
                    ? WhatsAppIcon
                    : LinkedinIcon
                }
              />
              <span className="dashboard-header-publish-menu-span input-hover">
                {m.text}
              </span>
            </div>
          );
        })}
      </StyledMenu>
    </div>
  );
}

export default DashboardHeader;
