import React, { useEffect, useState } from "react";
import "./style.css";
import TextIput, { InputType } from "../../textIput";
import DeactiveAddIcon from "../../../img/AddSquare.svg";
import axios from "axios";
import { useDispatch } from "react-redux";
import CircularIndeterminate from "../../circularIndeterminate";
import SnackBar from "../../snackBar";
import { setSnackMessage, SnackType } from "../../../reducer/snackbar";
import DashboardHeader from "../header";
import { Level, signout } from "../dashboard";
import DashboardFooter from "../footer";
import CheckBox from "../../checkbox";
import TextButton from "../../textButton";
import { useNavigate } from "react-router-dom";
import { api } from "../../../api";

export const TranslateLanguage = {
  language: "",
  caption: "",
};

function Translate({ accessToken, postId, setLevel, setPostsList }) {
  const [prompt, setPrompt] = useState("");
  const [lanSelected, setLangSelected] = useState([]);
  // const [postText, setPostText] = useState("");
  const [key, setKey] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [checkedLag, setCheckLang] = useState([]);

  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getPostDetail();
  }, []);

  const getPostDetail = () => {
    setShowProgress(true);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .get(`https://cfstagingapi.alo.hair/api/post/${postId}`, {
    //     headers: headers,
    //   })
    api("get", `post/${postId}`, {}, headers, navigate)
      .then((res) => {
        // setPostText(res.data.text);
        const postDetail = {
          lang: "English",
          cap: res.data.text,
          postId: postId,
        };
        const arr = [];
        arr.push(postDetail);
        setLangSelected(arr);

        setShowProgress(false);
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
          setShowProgress(false);
        }
      });
  };

  const translatePopularLang = (ss) => {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    return new Promise((resolve) => {
      axios
        .get(
          `https://cfstagingapi.alo.hair/api/post/translate/${postId}/${ss}`,
          {
            headers: headers,
          }
        )
        .then((res) => {
          const postDetail = {
            lang: ss,
            cap: res.data.text,
            postId: res.data.id,
          };
          resolve(postDetail);
        })
        .catch((e) => {
          if (e?.response?.status === 420) {
            signout(navigate);
          } else if (e?.response?.data?.detail) {
            reduxDispatch(
              setSnackMessage({
                snak_message: e.response.data.detail,
                snack_type: SnackType.Danger,
              })
            );
            setKey((k) => k + 1);
            resolve(null);
          }
        });
    });
  };

  const translateMultiPost = async () => {
    setShowProgress(true);

    const arr = [...lanSelected];

    const lagsName = lanSelected.map((l) => l.lang);
    const diffrenceLang = checkedLag.filter((ch) => !lagsName.includes(ch));

    for (let i = 0; i < diffrenceLang.length; i++) {
      const res = await translatePopularLang(diffrenceLang[i]);
      if (res) arr.push(res);
      else break;
    }
    setLangSelected(arr);
    setShowProgress(false);
  };

  const setPostTranslate = (translatePrompt) => {
    setShowProgress(true);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .get(
    //     `https://cfstagingapi.alo.hair/api/post/translate/${postId}/${translatePrompt}`,
    //     {
    //       headers: headers,
    //     }
    //   )
    api(
      "get",
      `post/translate/${postId}/${translatePrompt}`,
      {},
      headers,
      navigate
    )
      .then((res) => {
        setShowProgress(false);
        const postDetail = {
          lang: translatePrompt,
          cap: res.data.text,
          postId: res.data.id,
        };
        const arr = [...lanSelected];
        arr.push(postDetail);
        setLangSelected(arr);
        setPrompt("");
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
          setShowProgress(false);
        }
      });
  };

  return (
    <div
      style={{
        width: "90%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <DashboardHeader level={Level.Translate} accessToken={accessToken} />

      {showProgress && <CircularIndeterminate />}
      <SnackBar key={key} />

      <div
        style={{
          display: "flex",
          marginTop: "15px",
          gap: "20px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          <CheckBox
            onChange={(checked) => {
              if (checked) {
                const list = [...checkedLag];
                list.push("Spanish");
                setCheckLang(list);
              } else {
                const list = [...checkedLag];
                const idx = list.findIndex((l) => l === "Spanish");
                list.slice(idx, 1);
                setCheckLang(list);
              }
            }}
            label={<span>Spanish</span>}
            isDisabled={lanSelected.find((c) => c.lang === "Spanish")}
          />
          <CheckBox
            onChange={(checked) => {
              if (checked) {
                const list = [...checkedLag];
                list.push("French");
                setCheckLang(list);
              } else {
                const list = [...checkedLag];
                const idx = list.findIndex((l) => l === "French");
                list.slice(idx, 1);
                setCheckLang(list);
              }
            }}
            label={<span>French</span>}
            isDisabled={lanSelected.find((c) => c.lang === "French")}
          />
          <CheckBox
            onChange={(checked) => {
              if (checked) {
                const list = [...checkedLag];
                list.push("Arabic");
                setCheckLang(list);
              } else {
                const list = [...checkedLag];
                const idx = list.findIndex((l) => l === "Arabic");
                list.slice(idx, 1);
                setCheckLang(list);
              }
            }}
            label={<span>Arabic</span>}
            isDisabled={lanSelected.find((c) => c.lang === "Arabic")}
          />
        </div>
        <TextButton
          text="Translate"
          width="100px"
          onClick={() => translateMultiPost()}
          isDisabled={lanSelected.length === 0}
        />
      </div>

      <div className="translate-container">
        {lanSelected.length > 0 &&
          lanSelected.map((ls) => {
            return (
              <div
                key={ls.postId}
                className="translate-lua-caption"
                style={{
                  width:
                    lanSelected.length === 1
                      ? "calc(100% - 5px)"
                      : "calc(50% - 20px)",
                }}
              >
                <span>{`Content Factory Caption(${ls.lang.toUpperCase()})`}</span>
                <div style={{ overflowY: "auto" }}>
                  <span>{ls.cap}</span>
                </div>
              </div>
            );
          })}
      </div>

      <DashboardFooter
        defaultValue={prompt}
        nextButtonClick={(prompt) => {
          setPrompt(prompt);
          if (prompt === "") {
            setPostsList(lanSelected);
            setLevel(Level.Publish);
          } else setPostTranslate(prompt);
        }}
        // key={prompt}
        key={lanSelected.length}
        level={Level.Translate}
        btnText={prompt === "" ? "Skip" : "Next"}
        setPrompt={(val) => setPrompt(val)}
      />
    </div>
  );
}
export default Translate;
