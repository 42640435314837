import React, { useEffect, useState } from "react";
import "./style.css";
import TextInput, { InputType } from "../textIput";
import PrimaryButton from "../primaryButton";
import HideIcon from "../../img/Hide.svg";

function NewPassWord() {
  const [password, setPassword] = useState("");
  const [cofirmPassword, setConformPassword] = useState("");
  const [match, setMatch] = useState(true);
  const [btnDisable, setBtnDisabled] = useState(true);

  useEffect(() => {
    if (password !== "") {
      if (
        password !== "" &&
        cofirmPassword !== "" &&
        password === cofirmPassword
      ) {
        setMatch(true);
      } else setMatch(false);
    }
    if (password !== "" && cofirmPassword !== "" && password === cofirmPassword)
      setBtnDisabled(false);
    else setBtnDisabled(true);
  }, [password, cofirmPassword]);

  return (
    <div className="newpass-form-cotainer">
      <div className="newpass-form-cotainer-div">
        <div className="newpass-form-cotainer-flex">
          <span className="newpass-header">Create New Password</span>
        </div>

        <div className="newpass-form-cotainer-flex">
          <TextInput
            legend="Password"
            width="100%"
            icon={HideIcon}
            iconText="Hide"
            type={InputType.PASSWORD}
            passValidate={true}
            onChange={(val) => setPassword(val)}
          />

          <span className="newpass-hint ">Must be at least 8 characters</span>

          <TextInput
            legend="Confirm Password"
            width="100%"
            style={{ marginTop: "24px" }}
            type={InputType.PASSWORD}
            onChange={(val) => setConformPassword(val)}
            passValidate
          />

          {!match && (
            <span className="newpass-email-err">
              New password and confirm new password do not match
            </span>
          )}
        </div>

        <div
          className="newpass-form-cotainer-flex"
          style={{ gap: "16px", marginTop: "32px" }}
        >
          <PrimaryButton
            text="Reset Password"
            width="167px"
            isDisabled={btnDisable}
          />
        </div>
      </div>
    </div>
  );
}

export default NewPassWord;
