import React, { useCallback, useEffect, useRef, useState } from "react";
import LunaIcon from "../../img/Cover.jpg";
import "./style.css";
import { colors } from "../../theme";
import Header from "../header";
import SignUp from "../signup";
import SignIn from "../signin";
import ResetPassWord from "../resetPass";
import CheckEmail from "../checkEmail";
import Verification from "../verification";
import NewPassWord from "../newPass";
import Alert from "@mui/material/Alert";

export const ComponentEnum = {
  SignUp: 0,
  SignIn: 1,
  ResetPass: 2,
  CheckEmail: 3,
  Verfication: 4,
  NewPass: 5,
};

function LoginSignUpFix() {
  const [componetState, setComponentState] = useState(ComponentEnum.SignUp);
  const [errorMessage, setErrorMessage] = useState("");
  const [resMessage, setReMessage] = useState("");

  const changeState = (newState) => {
    setComponentState(newState);
  };

  return (
    <>
      <Header showProfileIcon={false} />
      <div style={{ backgroundColor: colors.Black }}>
        {errorMessage !== "" && (
          <div
            style={{ position: "absolute", top: "50%", left: "50%", zIndex: 1 }}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </div>
        )}
        {resMessage !== "" && (
          <div
            style={{ position: "absolute", top: "50%", left: "50%", zIndex: 1 }}
          >
            <Alert severity="success">{resMessage}</Alert>
          </div>
        )}
        <div className="signup-container">
          <div className="signup-img-cotainer">
            <img
              style={{ height: "100%", width: "100%" }} //, objectFit: "cover"
              src={LunaIcon}
            />
          </div>
          <>
            {componetState === ComponentEnum.SignUp ? (
              <SignUp
                changeState={changeState}
                setErrorMessage={(msg) => setErrorMessage(msg)}
                setResMessage={(msg) => setReMessage(msg)}
                style={{ opacity: errorMessage !== "" ? 0.5 : 1 }}
              />
            ) : componetState === ComponentEnum.SignIn ? (
              <SignIn
                changeState={changeState}
                setErrorMessage={(msg) => setErrorMessage(msg)}
                setResMessage={(msg) => setReMessage(msg)}
                style={{ opacity: errorMessage !== "" ? 0.5 : 1 }}
              />
            ) : componetState === ComponentEnum.ResetPass ? (
              <ResetPassWord
                changeState={changeState}
                style={{ opacity: errorMessage !== "" ? 0.5 : 1 }}
              />
            ) : componetState === ComponentEnum.CheckEmail ? (
              <CheckEmail
                changeState={changeState}
                style={{ opacity: errorMessage !== "" ? 0.5 : 1 }}
              />
            ) : componetState === ComponentEnum.Verfication ? (
              <Verification
                changeState={changeState}
                style={{ opacity: errorMessage !== "" ? 0.5 : 1 }}
              />
            ) : componetState === ComponentEnum.NewPass ? (
              <NewPassWord
                changeState={changeState}
                style={{ opacity: errorMessage !== "" ? 0.5 : 1 }}
              />
            ) : null}
          </>
        </div>
      </div>
    </>
  );
}

export default LoginSignUpFix;
