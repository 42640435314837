import { configureStore } from "@reduxjs/toolkit";
import UserInfoReducer from "../reducer/userinfo";
import SnackBarReducer from "../reducer/snackbar";

export const store = configureStore({
  reducer: {
    userInfo: UserInfoReducer,
    snackreducer: SnackBarReducer,
  },
});
