// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-title-span {
  color: var(--gray100);
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.56px;
}

.setting-input-container {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 30px;
}

.setting-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.setting-btn-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
  gap: 15px;
}

.setting-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/dashboard/setting/style.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,oBAAoB;EACpB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,WAAW;AACb","sourcesContent":[".setting-title-span {\n  color: var(--gray100);\n  font-family: Inter;\n  font-size: 28px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: 0.56px;\n}\n\n.setting-input-container {\n  width: 70%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n  margin-top: 30px;\n}\n\n.setting-header-container {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 15px;\n}\n\n.setting-btn-container {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: end;\n  gap: 15px;\n}\n\n.setting-flex {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
