import React from "react";
import "./style.css";
import PostPublishIcon from "../../../img/PostPublish.svg";
import BoldTickIcon from "../../../img/BoldTick.svg";
import PrimaryButton from "../../primaryButton";

function PostPublished({ analyseClick }) {
  return (
    <div className="post-published-container">
      <img src={PostPublishIcon} />
      <div className="post-published-div">
        <div className="post-publish-tick-container">
          <img src={BoldTickIcon} />
        </div>
        <span className="post-published-span">
          Your Post is <br /> Published
        </span>
        <PrimaryButton
          text="Analyze"
          width="167px"
          height="56px"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            analyseClick();
          }}
        />
      </div>
    </div>
  );
}

export default PostPublished;
