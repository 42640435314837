// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-container {
  /* width: 100vw; */
  /* height: 100vh; */
  height: calc(100vh - 19px);
  display: flex;
}

.signup-img-cotainer {
  display: flex;
  flex: 1.5 1;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/loginSignupFix/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,0BAA0B;EAC1B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,WAAS;EACT,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".signup-container {\n  /* width: 100vw; */\n  /* height: 100vh; */\n  height: calc(100vh - 19px);\n  display: flex;\n}\n\n.signup-img-cotainer {\n  display: flex;\n  flex: 1.5;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
