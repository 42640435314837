import React, { useState } from "react";
import "./style.css";
import TextInput, { InputType } from "../textIput";
import PrimaryButton from "../primaryButton";

function Verification() {
  const [code, setCode] = useState(-1);
  return (
    <div className="verification-form-cotainer">
      <div className="verification-form-cotainer-div">
        <div className="verification-form-cotainer-flex">
          <span className="verification-header">Verification</span>
          <span className="verification-h5">Verify your code.</span>
        </div>

        <TextInput
          legend="Verification Code"
          width="70%"
          style={{ marginTop: "48px" }}
          type={InputType.NUMBER}
          onChange={(val) => (val === "" ? setCode(-1) : setCode(val))}
        />

        <div
          className="verification-form-cotainer-flex"
          style={{ gap: "16px", marginTop: "32px" }}
        >
          <PrimaryButton
            text="Verify Code"
            width="167px"
            isDisabled={code === -1}
          />
        </div>
      </div>
    </div>
  );
}

export default Verification;
