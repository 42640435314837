// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkemail-form-cotainer {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  flex: 2 1;
  margin-top: 77px;
}

.checkemail-form-cotainer-flex {
  /* display: flex;
  align-items: start;
  justify-content: center; */
  width: 70%;
  /* flex-direction: column; */
}

.checkemail-form-cotainer-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 15px;
  width: 100%;
}

.checkemail-header {
  color: var(--Gray-100, #f3f7fd);
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.68px;
  margin-bottom: 16px;
}

.checkemail-desc {
  color: var(--gray500);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
}

.checkemail-resend {
  color: var(--primary);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.checkemail-resend:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/checkEmail/style.css"],"names":[],"mappings":"AAAA;EACE;;;4BAG0B;EAC1B,SAAO;EACP,gBAAgB;AAClB;;AAEA;EACE;;4BAE0B;EAC1B,UAAU;EACV,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,SAAS;EACT,WAAW;AACb;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB","sourcesContent":[".checkemail-form-cotainer {\n  /* display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center; */\n  flex: 2;\n  margin-top: 77px;\n}\n\n.checkemail-form-cotainer-flex {\n  /* display: flex;\n  align-items: start;\n  justify-content: center; */\n  width: 70%;\n  /* flex-direction: column; */\n}\n\n.checkemail-form-cotainer-div {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin: auto;\n  gap: 15px;\n  width: 100%;\n}\n\n.checkemail-header {\n  color: var(--Gray-100, #f3f7fd);\n  font-family: Inter;\n  font-size: 34px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: 0.68px;\n  margin-bottom: 16px;\n}\n\n.checkemail-desc {\n  color: var(--gray500);\n  font-family: Inter;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 32px; /* 200% */\n}\n\n.checkemail-resend {\n  color: var(--primary);\n  font-family: Inter;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 32px;\n}\n.checkemail-resend:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
