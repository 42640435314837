import React, { useEffect, useRef, useState } from "react";
import ShowIcon from "../../img/Show.svg";

import "./style.css";

export const InputType = {
  TEXT: "text",
  FILE: "file",
  PASSWORD: "password",
  EMAIL: "email",
  NUMBER: "number",
};

function TextInput({
  legend,
  icon,
  iconText,
  width,
  style,
  className,
  type,
  onChange,
  passValidate,
  placeholder = "",
  inputIcon,
  acceptFiles = ".pdf, .docs",
  isDisabled = false,
  imageClick,
  defaultValue = "",
}) {
  const [validate, setValidate] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [fileName, setFileName] = useState("");
  const [showValidateModal, setShowValidateModal] = useState(false);

  const passwordVal = useRef({
    uppercase: false,
    lowercase: false,
    specialChar: false,
    numeral: false,
    minchar: false,
    maxchar: true,
    valid: false,
  });

  const input = useRef(null);

  useEffect(() => {
    if (passValidate) {
      if (
        passwordVal.current.uppercase &&
        passwordVal.current.lowercase &&
        passwordVal.current.specialChar &&
        passwordVal.current.numeral &&
        passwordVal.current.minchar &&
        passwordVal.current.maxchar
      )
        setValidate(true);
      else setValidate(false);
    }
  }, [passwordVal]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const password = useRef("");

  const checkPasswordHandler = (event) => {
    password.current = event.target.value;
    let pass = event.target.value;

    const obj = { ...passwordVal.current };

    var lowerCaseLetters = new RegExp("[a-z]");
    if (pass.match(lowerCaseLetters)) {
      obj.lowercase = true;
    } else {
      obj.lowercase = false;
    }
    //Validate capital letters
    var upperCaseLetters = new RegExp("[A-Z]");
    if (pass.match(upperCaseLetters)) {
      obj.uppercase = true;
    } else {
      obj.uppercase = false;
    }
    //Validate a number
    var numbers = new RegExp("[0-9]");
    if (pass.match(numbers)) {
      obj.numeral = true;
    } else {
      obj.numeral = false;
    }
    // Validate minimum length
    var regex = new RegExp("[.*]{8,}");
    if (pass.length >= 8) {
      obj.minchar = true;
    } else {
      obj.minchar = false;
    }
    // Validate maximum length
    // var regex = new RegExp("[.*]{8,50}");
    // if (pass.length > 10) {
    //   obj.maxchar = true;
    // } else {
    //   obj.maxchar = false;
    // }
    //Validate Special Character
    var regex = new RegExp("[-@#$%^&]");
    if (pass.match(regex)) {
      obj.specialChar = true;
    } else {
      obj.specialChar = false;
    }

    if (
      obj.lowercase &&
      obj.uppercase &&
      obj.numeral &&
      obj.minchar &&
      obj.maxchar &&
      obj.specialChar
    ) {
      obj.valid = true;
      setShowValidateModal(false);
    } else {
      obj.valid = false;
      setShowValidateModal(true);
    }

    passwordVal.current = obj;
  };

  return (
    <div
      style={{ ...style, width: width }}
      className={`${className} text-input-contaier`}
    >
      <div className="text-input-legend-contaier">
        {legend && <span className="text-input-legend">{legend}</span>}
        {icon && (
          <div
            className="text-input-show-pass"
            onClick={() => setShowPass(!showPass)}
          >
            <img src={showPass ? ShowIcon : icon} />
            <span className="text-input-legend">
              {showPass ? "Show" : iconText}
            </span>
          </div>
        )}
      </div>
      {type === InputType.FILE ? (
        <div
          className={`text-input ${
            validate ? "valid-input" : "not-valid-input"
          } `}
        >
          <input
            ref={input}
            disabled={isDisabled}
            placeholder={placeholder}
            accept={acceptFiles}
            style={{
              display: "none",
              background: "transparent",
              border: "none",
              outline: "unset",
              width: "100%",
            }}
            type={type}
            onChange={(e) => {
              setFileName(e.target.files[0].name);
              const fileUploaded = e.target.files[0];
              onChange(fileUploaded);
            }}
          ></input>
          <span style={{ marginLeft: "15px" }}>
            {defaultValue
              ? defaultValue
              : fileName === ""
              ? "Attach your file"
              : fileName}
          </span>
          {inputIcon && (
            <img
              style={{ marginRight: "15px" }}
              src={inputIcon}
              onClick={() => {
                input.current.click();
              }}
            />
          )}
        </div>
      ) : (
        <div
          className={`text-input ${
            validate ? "valid-input" : "not-valid-input"
          } `}
        >
          <input
            maxLength={passValidate && 10}
            ref={input}
            disabled={isDisabled}
            placeholder={placeholder}
            accept={acceptFiles}
            style={{
              display: "flex",
              background: "transparent",
              border: "none",
              outline: "unset",
              width: "100%",
            }}
            defaultValue={defaultValue}
            type={
              type === InputType.PASSWORD
                ? showPass
                  ? "text"
                  : "password"
                : type
            }
            onFocus={() => {
              if (passValidate) {
                if (passwordVal.current.valid) {
                  setShowValidateModal(false);
                } else {
                  setShowValidateModal(true);
                }
              }
            }}
            onBlur={() => {
              if (type === InputType.PASSWORD) {
                setShowValidateModal(false);
              }
            }}
            onChange={(e) => {
              if (type === InputType.EMAIL) {
                const res = validateEmail(e.target.value);
                if (res) {
                  onChange(e.target.value);
                  setValidate(true);
                } else {
                  onChange("");
                  setValidate(false);
                }
              } else if (type === InputType.PASSWORD && passValidate) {
                checkPasswordHandler(e);
                if (passwordVal.current.valid) {
                  onChange(password.current);
                  setValidate(true);
                } else {
                  onChange("");
                  setValidate(false);
                }
              } else onChange(e.target.value);
            }}
          ></input>
          {passValidate && showValidateModal && (
            <div
              id="message"
              className="text-input-pass-popup"
              style={{ display: "block" }}
            >
              <p
                id="letter"
                className={passwordVal.current.lowercase ? "valid" : "invalid"}
              >
                Must have 1 Lower Case Alphabet
              </p>
              <p
                id="capital"
                className={passwordVal.current.uppercase ? "valid" : "invalid"}
              >
                Must have 1 Upper Case Alphabet
              </p>
              <p
                id="number"
                className={passwordVal.current.numeral ? "valid" : "invalid"}
              >
                Must have 1 Numeral Case Alphabet
              </p>
              <p
                id="length"
                className={passwordVal.current.minchar ? "valid" : "invalid"}
              >
                Minimum 8 characters required
              </p>
              <p
                id="length"
                className={passwordVal.current.maxchar ? "valid" : "invalid"}
              >
                Maximum of 10 characters only
              </p>
              <p
                id="specialchar"
                className={
                  passwordVal.current.specialChar ? "valid" : "invalid"
                }
              >
                Must have 1 Special Character [-@#$%^&]
              </p>
            </div>
          )}
          {inputIcon && (
            <img
              style={{ marginRight: "15px" }}
              src={inputIcon}
              onClick={() => {
                imageClick();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default TextInput;
