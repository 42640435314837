import React, { useEffect, useState } from "react";
import "./style.css";
import AddIcon from "../../../img/BlueAddSquare.svg";
import MessageProgrammingIcon from "../../../img/MessageProgramming.svg";
import SignpostIcon from "../../../img/Signpost.svg";
import TextButton from "../../textButton";
import { InputType } from "../../textIput";
import axios from "axios";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import BasicModal from "../../modal";
import DashboardHeader from "../header";
import { Level } from "../dashboard";
import DashboardFooter from "../footer";
import CircularIndeterminate from "../../circularIndeterminate";
import SnackBar from "../../snackBar";
import { useDispatch } from "react-redux";
import { setSnackMessage, SnackType } from "../../../reducer/snackbar";
import RadioButton from "../../radioButton";
import { useNavigate } from "react-router-dom";
import { api, basePath } from "../../../api";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    backgroundColor: "var(--gray800)",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const SelectedType = {
  None: 1,
  Url: 2,
  File: 3,
  Trend: 4,
};

function TopicLevel({
  accessToken,
  setLevel,
  setAddedPostId,
  setIsVideoMedia,
}) {
  const [prompt, setPrompt] = useState("");
  const [trendsList, setTrendsList] = useState([]);
  const [showProgress, setShowProgress] = useState(false);

  const [selectedTrend, setSelectedTrend] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [inputType, setInputType] = useState();

  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState();
  const [url, setUrl] = useState("");
  const [key, setKey] = useState(0);
  const [media, setMedia] = useState("NONE");

  const [selectedType, setSelectedType] = useState(SelectedType.None);

  let interval;
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setPrompt("Summarize the following text");
    setMedia("NONE");
    setSelectedType(SelectedType.None);
    setKey((k) => k + 1);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const uploadFile = () => {
    return new Promise((resolve) => {
      const formData = new FormData();
      formData.append("file", file);

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
      };

      axios
        .post(`${basePath}/upload/`, formData, {
          headers: headers,
        })
        .then((res) => {
          resolve(res.data.filename);
        })
        .catch((e) => {
          setShowProgress(false);
          if (e?.response?.data?.detail) {
            reduxDispatch(
              setSnackMessage({
                snak_message: e.response.data.detail,
                snack_type: SnackType.Danger,
              })
            );
            setKey((k) => k + 1);
          }
        });
    });
  };

  const checkVideoUrl = (postId) => {
    setShowProgress(true);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    api("get", `post/${postId}`, {}, headers, navigate)
      .then((res) => {
        if (res.data.video_url) {
          clearInterval(interval);
          setShowProgress(false);
          setAddedPostId(postId);
          setLevel(Level.SocialReady);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
          setShowProgress(false);
        }
      });
  };

  const createPost = () => {
    if (media === "VIDEO") {
      reduxDispatch(
        setSnackMessage({
          snak_message:
            "Making the video takes between 1 and 6 minutes. Please do not leave the page",
          snack_type: SnackType.Primary,
        })
      );
      setKey((k) => k + 1);
    }

    setShowProgress(true);

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      Connection: "keep-alive",
    };

    if (fileName !== "") {
      uploadFile().then((filename) => {
        const body = {
          media: media,
          blog_url: url,
          doc_name: filename,
          prompt: prompt,
        };

        api("post", "post/create", body, headers, navigate)
          .then((res) => {
            if (media === "VIDEO") {
              interval = setInterval(() => {
                checkVideoUrl(res.data.post.id);
              }, 30000);
            } else {
              setShowProgress(false);
              setAddedPostId(res.data.post.id);
              setLevel(Level.SocialReady);
            }
          })
          .catch((e) => {
            setShowProgress(false);
            if (e?.response?.data?.detail) {
              reduxDispatch(
                setSnackMessage({
                  snak_message: e.response.data.detail,
                  snack_type: SnackType.Danger,
                })
              );
              setKey((k) => k + 1);
            }
          })
          .finally(() => {
            if (media === "VIDEO") setIsVideoMedia(true);
            else setIsVideoMedia(false);
          });
      });
    } else {
      const body = {
        media: media,
        blog_url: url,
        doc_name: "",
        prompt: prompt,
      };

      api("post", "post/create", body, headers, navigate, 600000)
        .then((res) => {
          if (media === "VIDEO") {
            interval = setInterval(() => {
              checkVideoUrl(res.data.post.id);
            }, 30000);
          } else {
            setShowProgress(false);
            setAddedPostId(res.data.post.id);
            setLevel(Level.SocialReady);
          }
        })
        .catch((e) => {
          setShowProgress(false);
          if (e?.response?.data?.detail) {
            reduxDispatch(
              setSnackMessage({
                snak_message: e.response.data.detail,
                snack_type: SnackType.Danger,
              })
            );
            setKey((k) => k + 1);
          }
        })
        .finally(() => {
          if (media === "VIDEO") setIsVideoMedia(true);
          else setIsVideoMedia(false);
        });
    }
  };

  const getGoogleTrends = (e) => {
    setShowProgress(true);
    // axios
    //   .get("https://cfstagingapi.alo.hair/api/trends/10")
    api("get", "trends/10", {}, {}, navigate)
      .then((res) => {
        setTrendsList(res.data);
        handleClick(e);
      })
      .catch((e) => console.log(e))
      .finally(() => setShowProgress(false));
  };

  return (
    <div
      style={{
        width: "90%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {showProgress && <CircularIndeterminate />}

      <DashboardHeader level={Level.Topic} accessToken={accessToken} />
      <SnackBar key={key} />
      <div className="topic-container">
        <BasicModal
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          inputType={inputType}
          onClick={(value) => {
            if (inputType === InputType.TEXT) {
              setUrl(value);
              setSelectedType(SelectedType.Url);
            } else if (inputType === InputType.FILE) {
              setFileName(value.name);
              setFile(value);
              setSelectedType(SelectedType.File);
            }
            setModalOpen(false);
          }}
          defaultValue={inputType === InputType.TEXT ? url : fileName}
          placeholder={selectedType === SelectedType.Url && "Enter a url here"}
        />
        <div className="dashboard-radio-div">
          <span>Select your media type:</span>
          <RadioButton
            onChange={(val) => setMedia(val)}
            defaultCheckedId="NONE"
            labels={[
              { label: "Text", id: "NONE" },
              { label: "Image", id: "IMAGE" },
              { label: "Video", id: "VIDEO" },
            ]}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexWrap: "wrap",
            gap: "12px",
          }}
        >
          {/* <div className="topic-first-row" style={{ alignItems: "start" }}> */}
          <div className="topic-first-row" style={{ alignItems: "center" }}>
            <div
              className="topic-box"
              style={{
                backgroundColor:
                  url === "" ? "transparent" : "rgba(168, 199, 250, 0.25)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "10px",
                  width: "calc(100% - 70px)",
                }}
              >
                <img src={MessageProgrammingIcon} />
                <span className="topic-box-span">
                  {url === ""
                    ? "If you have a URL link to post"
                    : `Your selected url is ${url}`}
                </span>
              </div>
              <TextButton
                onClick={() => {
                  if (url === "") {
                    setModalOpen(true);
                    setInputType(InputType.TEXT);
                    setSelectedType(SelectedType.Url);
                    setKey((k) => k + 1);
                  } else {
                    setUrl("");
                    setSelectedType(SelectedType.None);
                    setKey((k) => k + 1);
                  }
                }}
                text={url === "" ? "Click here" : "Remove"}
                width="100px"
                className="textbtn-span"
                isDisabled={selectedTrend !== "" || fileName !== ""}
              />
            </div>

            <div
              className="topic-box"
              style={{
                backgroundColor:
                  fileName === "" ? "transparent" : "rgba(168, 199, 250, 0.25)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "10px",
                  width: "calc(100% - 70px)",
                }}
              >
                <img src={AddIcon} />
                <span className="topic-box-span">
                  {fileName === ""
                    ? "If you have a file for new post"
                    : `Your selected file name is ${fileName}`}{" "}
                </span>
              </div>
              <TextButton
                onClick={() => {
                  if (fileName === "") {
                    setModalOpen(true);
                    setInputType(InputType.FILE);
                    setKey((k) => k + 1);
                  } else {
                    setFileName("");
                    setFile(null);
                    setSelectedType(SelectedType.None);
                    setKey((k) => k + 1);
                  }
                }}
                text={fileName === "" ? "Click here" : "Remove"}
                width="100px"
                className="textbtn-span"
                isDisabled={selectedTrend !== "" || url !== ""}
              />
            </div>
          </div>

          {/* <div className="topic-first-row" style={{ alignItems: "end" }}> */}
          <div className="topic-first-row" style={{ alignItems: "center" }}>
            <div
              className="topic-box"
              style={{
                backgroundColor:
                  selectedTrend === ""
                    ? "transparent"
                    : "rgba(168, 199, 250, 0.25)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "10px",
                  width: "calc(100% - 70px)",
                }}
              >
                <img src={SignpostIcon} />
                <span className="topic-box-span">
                  {selectedTrend === ""
                    ? "If you need Recommendation "
                    : `Your selected trend is "${selectedTrend}"`}
                </span>
              </div>
              <TextButton
                text={selectedTrend === "" ? "Click here" : "Remove"}
                width={showProgress ? "110px" : "100px"}
                className="textbtn-span"
                onClick={(e) => {
                  if (selectedTrend === "") getGoogleTrends(e.currentTarget);
                  else {
                    setSelectedTrend("");
                    setSelectedType(SelectedType.None);
                    setKey((k) => k + 1);
                  }
                }}
                isDisabled={url !== "" || fileName !== ""}
              />
            </div>
          </div>
        </div>

        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {trendsList.map((t) => {
            return (
              <div
                onClick={() => {
                  setSelectedTrend(t);
                  setPrompt(`Tell me about ${t}`);
                  handleClose();
                  setKey((k) => k + 1);
                  setSelectedType(SelectedType.Trend);
                  setKey((k) => k + 1);
                }}
                className="dashboard-header-publish-menu-div"
              >
                <span className="dashboard-header-publish-menu-span input-hover">
                  {t}
                </span>
              </div>
            );
          })}
        </StyledMenu>
      </div>

      <DashboardFooter
        key={key}
        defaultValue={prompt}
        nextButtonClick={() => {
          setPrompt(prompt);
          createPost();
        }}
        setPrompt={(val) => setPrompt(val)}
        nextDisabled={prompt === ""}
        level={Level.Topic}
        selectedType={selectedType}
      />
    </div>
  );
}
export default TopicLevel;
