import React, { useEffect, useState } from "react";
import Header from "../../header";
import SideNav from "../../sideNav";
import "./style.css";
import TopicLevel from "../topicLevel";
import ConnectToSocial from "../connectToSocial";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SocialReady from "../socialReady";
import Translate from "../translate";
import Publish from "../publish";
import PostsList from "../postsList";
import PostPublished from "../postPublished";
import { useNavigate } from "react-router-dom";
import { setSnackMessage, SnackType } from "../../../reducer/snackbar";
import SnackBar from "../../snackBar";
import Setting from "../setting";
import { api } from "../../../api";

export const Level = {
  Topic: 1,
  SocialReady: 2,
  Translate: 3,
  Publish: 4,
};

export const NavSideClick = {
  NewPost: 1,
  SocialConnect: 2,
  PostList: 3,
  PostAnalyze: 4,
  FAQ: 5,
  Help: 6,
  SignOut: 7,
  Setting: 8,
};

export const signout = (navigate) => {
  const accessToken = localStorage.getItem("accessToken");

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const body = {};

  axios
    .post(
      "https://cfstagingapi.alo.hair/api/logout",
      { ...body },
      {
        headers: headers,
      }
    )
    .then(() => {
      navigate("/");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userInfo");
    })
    .catch((e) => console.log(e));
};

function Dashboard() {
  const [sideNavShow, setSideNavShow] = useState(true);
  const [level, setLevel] = useState(Level.Topic);
  const [dashboardContext, setDashboardContext] = useState(
    NavSideClick.NewPost
  );
  const [key, setKey] = useState(0);

  const [addedPostId, setAddedPostId] = useState(-1);

  const [postPublished, setPostPublished] = useState(false);
  const [lanSelected, setLangSelected] = useState([]);
  const [isVideoMedia, setIsVideoMedia] = useState(false);

  // const userInfo = useSelector((state) => state.userInfo);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userInfo"))
  );

  const reduxDispatch = useDispatch();

  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");

  // useEffect(() => {
  //   const info = localStorage.getItem("userInfo");
  //   if (info) {
  //     const infos = JSON.parse(info);
  //     setUserInfo(infos);
  //   }
  //   function storageEventHandler(event) {
  //     if (event.key === "userInfo") {
  //       const infos = JSON.parse(event.newValue);
  //       setUserInfo(infos);
  //     }
  //   }
  //   window.addEventListener("storage", storageEventHandler);
  //   return () => {
  //     window.removeEventListener("storage", storageEventHandler);
  //   };
  // }, []);

  useEffect(() => {
    getUserChannels();
  }, []);

  const sideNavClick = (e) => {
    if (e === NavSideClick.SignOut) {
      signout(navigate);
    } else setDashboardContext(e);
    if (e === NavSideClick.NewPost) {
      setLevel(Level.Topic);
      setPostPublished(false);
      getUserChannels();
    } else setPostPublished(false);
  };

  const getUserChannels = () => {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .get("https://cfstagingapi.alo.hair/api/channels/own", {
    //     headers: headers,
    //   })
    api("get", "channels/own", {}, headers, navigate)
      .then((res) => {
        if (!res.data || (res.data && res.data.length === 0)) {
          reduxDispatch(
            setSnackMessage({
              snak_message: "Please connect a platform first.",
              snack_type: SnackType.Warning,
            })
          );
          // setKey((k) => k + 1);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          // setKey((k) => k + 1);
        }
      });
  };

  return (
    <div className="dashboard-container">
      <SnackBar key={key} />
      <Header
        showBurger
        burgerClick={() => {
          setSideNavShow(!sideNavShow);
        }}
        userName={userInfo?.first_name ?? ""}
      />
      <div className="dashboard-div-container">
        <div style={{ flex: sideNavShow ? 1 : 0 }}>
          <SideNav
            style={{ display: sideNavShow ? "flex" : "none" }}
            onClick={(e) => sideNavClick(e)}
            selectedPage={dashboardContext}
          />
        </div>
        <div
          className="dashboard-right-component"
          style={{
            justifyContent: level === Level.Publish ? "start" : "space-between",
            alignItems: "center",
          }}
          key={level}
        >
          {postPublished ? (
            <PostPublished
              analyseClick={() => {
                sideNavClick(NavSideClick.PostAnalyze);
                setPostPublished(false);
              }}
            />
          ) : (
            dashboardContext === NavSideClick.NewPost && (
              <>
                {level === Level.Topic && (
                  <TopicLevel
                    setAddedPostId={setAddedPostId}
                    accessToken={accessToken}
                    setLevel={setLevel}
                    setIsVideoMedia={(val) => setIsVideoMedia(val)}
                  />
                )}
                {level === Level.SocialReady && (
                  <SocialReady
                    postId={addedPostId}
                    accessToken={accessToken}
                    setLevel={setLevel}
                    isVideo={isVideoMedia}
                  />
                )}
                {level === Level.Translate && (
                  <Translate
                    postId={addedPostId}
                    accessToken={accessToken}
                    setLevel={setLevel}
                    setPostsList={(list) => setLangSelected(list)}
                  />
                )}
                {level === Level.Publish && (
                  <Publish
                    accessToken={accessToken}
                    setPostPublished={setPostPublished}
                    postsList={lanSelected}
                  />
                )}
              </>
            )
          )}
          {dashboardContext === NavSideClick.SocialConnect && (
            <ConnectToSocial userInfo={userInfo} />
          )}
          {dashboardContext === NavSideClick.PostList && (
            <PostsList
              accessToken={accessToken}
              reusePost={(id) => {
                setAddedPostId(id);
                setDashboardContext(NavSideClick.NewPost);
                setLevel(Level.SocialReady);
              }}
              signout={() => signout(navigate)}
            />
          )}
          {dashboardContext === NavSideClick.Setting && (
            <Setting
              // userInfo={userInfo}
              accessToken={accessToken}
              userInfoChanged={() => {
                setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
