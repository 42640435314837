// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-btn-enable {
  height: 12px;
  background-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.primary-btn-enable span {
  color: var(--white);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.primary-btn-enable:hover {
  border-radius: 8px;
  background: #3178cc;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.primary-btn-disable {
  height: 12px;
  background: var(--gray200);
  border-radius: 8px;
  display: inline-flex;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.primary-btn-disable span {
  color: var(--gray300);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/Components/primaryButton/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gCAAgC;EAChC,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,8CAA8C;EAC9C,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".primary-btn-enable {\n  height: 12px;\n  background-color: var(--primary);\n  border-radius: 8px;\n  display: inline-flex;\n  padding: 16px 0;\n  justify-content: center;\n  align-items: center;\n  gap: 12px;\n}\n.primary-btn-enable span {\n  color: var(--white);\n  text-align: center;\n  font-family: Inter;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  cursor: pointer;\n}\n.primary-btn-enable:hover {\n  border-radius: 8px;\n  background: #3178cc;\n  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n}\n\n.primary-btn-disable {\n  height: 12px;\n  background: var(--gray200);\n  border-radius: 8px;\n  display: inline-flex;\n  padding: 16px 0;\n  justify-content: center;\n  align-items: center;\n  gap: 12px;\n}\n.primary-btn-disable span {\n  color: var(--gray300);\n  text-align: center;\n  font-family: Inter;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
