import React from "react";
import "./style.css";

function CheckBox({
  label,
  id,
  onChange,
  defaultChecked = false,
  isDisabled = false,
}) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        defaultChecked={defaultChecked}
        type="checkbox"
        id={id}
        name={id}
        onChange={(e) => onChange(e.target.checked)}
        disabled={isDisabled}
        className="input-hover"
      />
      <label className="checkbox-checkbox-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export default CheckBox;
