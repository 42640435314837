// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondry-btn-enable {
  height: 12px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--primary);
  display: inline-flex;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.secondry-btn-enable span {
  color: var(--primary);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.secondry-btn-enable:hover {
  background: rgba(71, 156, 255, 0.3);
  border-radius: 8px;
  cursor: pointer;
}

.secondry-btn-disable {
  height: 12px;
  border: var(--Spacing-Spacing-Atom, 1px) solid var(--gray300);
  background: transparent;
  border-radius: 8px;
  display: inline-flex;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.secondry-btn-disable span {
  color: var(--gray300);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/Components/secondryButton/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;EAClB,gCAAgC;EAChC,oBAAoB;EACpB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,6DAA6D;EAC7D,uBAAuB;EACvB,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".secondry-btn-enable {\n  height: 12px;\n  background-color: transparent;\n  border-radius: 8px;\n  border: 1px solid var(--primary);\n  display: inline-flex;\n  padding: 16px 0;\n  justify-content: center;\n  align-items: center;\n  gap: 12px;\n}\n.secondry-btn-enable span {\n  color: var(--primary);\n  text-align: center;\n  font-family: Inter;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  cursor: pointer;\n}\n.secondry-btn-enable:hover {\n  background: rgba(71, 156, 255, 0.3);\n  border-radius: 8px;\n  cursor: pointer;\n}\n\n.secondry-btn-disable {\n  height: 12px;\n  border: var(--Spacing-Spacing-Atom, 1px) solid var(--gray300);\n  background: transparent;\n  border-radius: 8px;\n  display: inline-flex;\n  padding: 16px 0;\n  justify-content: center;\n  align-items: center;\n  gap: 12px;\n}\n.secondry-btn-disable span {\n  color: var(--gray300);\n  text-align: center;\n  font-family: Inter;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
