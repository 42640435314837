import React, { useRef } from "react";
import LogoIcon from "../img/Logo.svg";
import TagIcon from "../img/Tag.svg";
import "./style.css";
import SecondryButton from "../Components/secondryButton";
import MacbookIcon from "../img/Macbook.svg";
import PrimaryButton from "../Components/primaryButton";
import NextIcon from "../img/WhiteNext.svg";
import LeftSideIcon from "../img/LeftSide.svg";
import CoinIcon from "../img/Coin.svg";
import JetIcon from "../img/Jet.svg";
import GearIcon from "../img/Gear.svg";
import TickCircleIcon from "../img/TickCircle.svg";
import BlueNextIcon from "../img/Next.svg";
import XIcon from "../img/X.svg";
import { useNavigate } from "react-router-dom";
import FacebookSocialIcon from "../img/FacebookSocial.svg";
import LinkedinSocialIcon from "../img/LinkedinSocial.svg";
import XSocialIcon from "../img/XSocial.svg";
import TextInput, { InputType } from "../Components/textIput";

function HomePage() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const pricingRef = useRef(null);
  const policyRef = useRef(null);
  const navigate = useNavigate();

  function header() {
    return (
      <div className="home-header">
        <div className="home-flex-div" style={{ marginLeft: "90px" }}>
          <div className="home-flex-div">
            <img
              src={LogoIcon}
              width="28px"
              height="28px"
              className="header-logo-icon"
            />
            <span className="home-header-nav-span">Content Factory</span>
            <img src={TagIcon} width="65px" height="16px" />
          </div>
          <div className="home-flex-div">
            <span
              className="home-header-nav-span"
              onClick={() => homeRef.current.scrollIntoView()}
            >
              Home
            </span>
            <span
              onClick={() => aboutRef.current.scrollIntoView()}
              className="home-header-nav-span"
            >
              About
            </span>
            <span
              className="home-header-nav-span"
              onClick={() => pricingRef.current.scrollIntoView()}
            >
              Pricing
            </span>
            <span
              className="home-header-nav-span"
              onClick={() => policyRef.current.scrollIntoView()}
            >
              Privacy Policy
            </span>
          </div>
        </div>
        <SecondryButton
          text="Log in/Sign up"
          width="162px"
          style={{ marginRight: "90px" }}
          onClick={() => {
            navigate("/loginnSignup");
          }}
        />
      </div>
    );
  }

  function home() {
    return (
      <div ref={homeRef} className="home-flex-div home-home">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            gap: "15px",
            marginLeft: "90px",
          }}
        >
          <span className="home-span1">CREATE SMARTER, NOT HARDER</span>
          <span className="home-span2">
            <span className="home-span-dicipline">
              An intelligent content production factory
            </span>
          </span>
          <span className="home-span3">
            Produce the desired content using the Content Factory, optimize and
            publish it
          </span>
          <div className="home-flex-div">
            <PrimaryButton
              text="Start now"
              width="155px"
              height="55px"
              onClick={() => {
                navigate("/loginnSignup");
              }}
              icon={NextIcon}
            />
            <SecondryButton
              text="Pricing"
              width="100px"
              height="55px"
              onClick={() => pricingRef.current.scrollIntoView()}
            />
          </div>
        </div>
        <img src={MacbookIcon} />
      </div>
    );
  }

  function about() {
    return (
      <div ref={aboutRef} className="home-flex-div">
        <div
          style={{
            flex: "1",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={LeftSideIcon} />
        </div>

        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            gap: "15px",
            marginRight: "20px",
          }}
        >
          <span className="home-span4">About US</span>
          <span className="home-span5">
            Get to know about{" "}
            <span className="home-span6">Content Factory AI</span>
          </span>
          <span className="home-span7">
            Produce the desired content using the Content Factory, optimize and
            publish it
          </span>
          <span className="home-span8">
            Maximize efficiency and streamline your content creation process,
            enabling you to deliver innovative and engaging posts in a fraction
            of the time.
          </span>
          <div className="home-flex-div">
            <PrimaryButton
              text="Start AI"
              width="137px"
              height="55px"
              onClick={() => {
                navigate("/loginnSignup");
              }}
              icon={NextIcon}
            />
          </div>
        </div>
      </div>
    );
  }

  function pricing() {
    return (
      <div
        ref={pricingRef}
        className="home-flex-div"
        style={{ justifyContent: "center" }}
      >
        <div className="home-price-deactive-box">
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <img src={GearIcon} />
            <span className="home-price-box-topic">
              Starter
              <br />
              {"  "}
              <span className="home-price-box-price">$29</span>
              <span className="home-price-box-time">/Per Month</span>
            </span>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <div className="home-thigs-div">
              <img src={TickCircleIcon} />
              <span>Email Marketing Content </span>
            </div>
            <div className="home-thigs-div">
              <img src={TickCircleIcon} />
              <span>Marketing Voice </span>
            </div>
            <div className="home-thigs-div">
              <img src={TickCircleIcon} />
              <span>Optimize SEO</span>
            </div>
            <div className="home-thigs-div">
              <img src={XIcon} />
              <span>Consulting Video </span>
            </div>
            <div className="home-thigs-div">
              <img src={XIcon} />
              <span>Marketing Advertising </span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <SecondryButton
              icon={BlueNextIcon}
              text="Purchase"
              width="100%"
              onClick={() => {}}
            />
          </div>
        </div>

        <div className="home-price-active-box">
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <img src={JetIcon} />
            <span className="home-price-box-topic">
              Starter
              <br />
              {"  "}
              <span className="home-price-box-price">$29</span>
              <span className="home-price-box-time">/Per Month</span>
            </span>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <div className="home-thigs-div">
              <img src={TickCircleIcon} />
              <span>Email Marketing Content </span>
            </div>
            <div className="home-thigs-div">
              <img src={TickCircleIcon} />
              <span>Marketing Voice </span>
            </div>
            <div className="home-thigs-div">
              <img src={TickCircleIcon} />
              <span>Optimize SEO</span>
            </div>
            <div className="home-thigs-div">
              <img src={XIcon} />
              <span>Consulting Video </span>
            </div>
            <div className="home-thigs-div">
              <img src={XIcon} />
              <span>Marketing Advertising </span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <PrimaryButton
              icon={NextIcon}
              text="Purchase"
              width="100%"
              onClick={() => {}}
            />
          </div>
        </div>

        <div className="home-price-deactive-box">
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <img src={CoinIcon} />
            <span className="home-price-box-topic">
              Organisation
              <br />
              {"  "}
              <span className="home-price-box-price">$99</span>
              <span className="home-price-box-time">/Per Month</span>
            </span>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <div className="home-thigs-div">
              <img src={TickCircleIcon} />
              <span>Email Marketing Content </span>
            </div>
            <div className="home-thigs-div">
              <img src={TickCircleIcon} />
              <span>Marketing Voice </span>
            </div>
            <div className="home-thigs-div">
              <img src={TickCircleIcon} />
              <span>Optimize SEO</span>
            </div>
            <div className="home-thigs-div">
              <img src={TickCircleIcon} />
              <span>Consulting Video </span>
            </div>
            <div className="home-thigs-div">
              <img src={TickCircleIcon} />
              <span>Marketing Advertising </span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <SecondryButton
              icon={BlueNextIcon}
              text="Purchase"
              width="100%"
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
    );
  }

  function policy() {
    return (
      <div ref={policyRef} className="home-policy">
        <span style={{ cursor: "default" }}>
          Introduction
          <br /> Welcome to our platform. We are committed to protecting your
          privacy and ensuring the security of your personal information. This
          Privacy Policy outlines how we collect, use, and safeguard your data
          when you use our services.
          <br /> <strong>1. Information We Collect</strong>
          <br /> When you use our platform, we may collect the following types
          of information: <br />
          <strong>Personal Information: </strong>
          This includes your name, email address, and other contact details when
          you create an account or contact us for support.
          <br />
          <strong> Social Media Accounts: </strong>
          When you connect your social media accounts (e.g., Instagram,
          Facebook, WhatsApp Business), we collect and store access tokens and
          relevant permissions to publish content on your behalf and retrieve
          analytics.
          <br /> <strong>Content Data:</strong> This includes the content you
          create and publish through our platform, such as images, videos, text,
          and associated metadata. <br />
          <strong>Usage Data: </strong>We collect information about how you use
          our platform, including your interactions with our services, IP
          address, browser type, and device information.
          <br />
          <strong> 2. How We Use Your Information </strong>
          <br />
          We use the information we collect for the following purposes: <br />
          <strong>Account Management:</strong> To create and manage your user
          account, authenticate your identity, and provide customer support.
          <br />
          <strong>Content Publishing:</strong> To allow you to create, publish,
          and manage content on your connected social media accounts.
          <br />
          <strong>Performance Tracking:</strong> To provide insights and
          analytics on the performance of your posts, including comments, likes,
          and views.
          <br /> <strong>Platform Improvement:</strong> To improve and enhance
          our platform, troubleshoot issues, and conduct research and analysis.
          <br />
          <strong> Communication:</strong> To communicate with you about
          updates, promotions, and other relevant information related to our
          services. <br />
          <strong>3. Sharing Your Information</strong>
          <br /> We do not sell, trade, or otherwise transfer your personal
          information to outside parties except in the following circumstances:
          <br />
          <strong> Service Providers:</strong> We may share your information
          with trusted third-party service providers who assist us in operating
          our platform and providing our services, as long as they agree to keep
          this information confidential. <br />
          <strong>Legal Compliance:</strong> We may disclose your information
          when required by law or to protect our rights, property, or safety and
          that of our users or others.
          <br />
          <strong> 4. Data Security</strong>
          <br /> We implement a variety of security measures to protect your
          personal information. These measures include encryption, access
          controls, and regular security assessments. However, no method of
          transmission over the internet or electronic storage is completely
          secure, so we cannot guarantee absolute security.
          <br />
          <strong> 5. Your Rights</strong> <br />
          You have the following rights regarding your personal information:
          <br />
          <strong>Access:</strong> You can request access to the personal
          information we hold about you. <br />
          <strong>Correction:</strong> You can request correction of any
          inaccurate or incomplete information.
          <br /> <strong>Deletion:</strong> You can request the deletion of your
          personal information, subject to certain legal obligations.
          <br />
          <strong>Withdrawal of Consent:</strong> You can withdraw your consent
          for us to process your personal information at any time.
          <br /> <strong>6. Third-Party Links</strong>
          <br /> Our platform may contain links to third-party websites. We are
          not responsible for the privacy practices or the content of these
          websites. We encourage you to read the privacy policies of any linked
          websites you visit. <br />
          <strong> 7. Changes to This Privacy Policy </strong>
          <br />
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. We will notify you of any
          significant changes by posting the updated policy on our platform and
          updating the effective date at the top of this page.
          <br /> <strong>8. Contact Us</strong>
          <br /> If you have any questions about this Privacy Policy or our data
          practices, please contact us at [your contact email].
          <br /> <strong>Effective Date:</strong> 01/06/2024 <br />
          By using our platform, you agree to the terms of this Privacy Policy.
          Thank you for trusting us with your personal information.
        </span>
      </div>
    );
  }

  function footer() {
    return (
      <div className="home-footer">
        <div className="home-footer-header">
          <div className="home-flex-div">
            <img src={LogoIcon} width="28px" height="28px" />
            <span className="home-header-nav-span">Content Factory</span>
            <img src={TagIcon} width="65px" height="16px" />
          </div>

          <div className="home-flex-div" style={{ gap: "50px" }}>
            <span
              className="home-header-nav-span"
              style={{ textDecoration: "underline" }}
              onClick={() => homeRef.current.scrollIntoView()}
            >
              Home
            </span>
            <span
              onClick={() => aboutRef.current.scrollIntoView()}
              style={{ textDecoration: "underline" }}
              className="home-header-nav-span"
            >
              About
            </span>
            <span
              className="home-header-nav-span"
              style={{ textDecoration: "underline" }}
              onClick={() => pricingRef.current.scrollIntoView()}
            >
              Pricing
            </span>
            <span
              className="home-header-nav-span"
              style={{ textDecoration: "underline" }}
              onClick={() => policyRef.current.scrollIntoView()}
            >
              Privacy Policy
            </span>
          </div>

          <div className="home-flex-div">
            <img src={FacebookSocialIcon} />
            <img src={LinkedinSocialIcon} />
            <img src={XSocialIcon} />
          </div>
        </div>

        <div
          className="home-flex-div"
          style={{ height: "330px", borderBottom: "1px solid var(--white)" }}
        >
          <div className="home-footer-contact-container">
            <div
              style={{
                background: "var(--primary)",
                padding: "10px",
                borderRadius: "8px",
              }}
            >
              <span style={{ color: "var(--black)", cursor: "default" }}>
                Contact us:
              </span>
            </div>
            <span style={{ cursor: "default" }}>Email: admin@squadland.io</span>
            <span style={{ cursor: "default" }}>Phone: 01438 94 0560</span>
            <span style={{ cursor: "default" }}>
              Address: Level 4,3 Shortlans, Hammersmith, W68DA London, United
              Kingdom
            </span>
          </div>
          <div className="home-footer-email-container">
            <TextInput
              placeholder="Email"
              type={InputType.EMAIL}
              onChange={(val) => {}}
              width="50%"
              style={{ gap: 0 }}
            />
            <PrimaryButton
              text="Subscribe to news"
              onClick={() => {}}
              icon={NextIcon}
              width="calc(50% - 25px)"
              height="54px"
            />
          </div>
        </div>
        <span className="home-header-nav-span" style={{ marginTop: "50px" }}>
          © 2023 Luna. All Rights Reserved.
        </span>
      </div>
    );
  }

  return (
    <div className="home-cotainer">
      {header()}
      <div style={{ width: "100vw", height: "100vh" }}>{home()}</div>
      <div style={{ width: "100vw", height: "100vh" }}>{about()}</div>
      <div style={{ width: "100vw", height: "100vh" }}>{pricing()}</div>
      <div style={{ width: "100vw" }}>{policy()}</div>
      {footer()}
    </div>
  );
}

export default HomePage;
