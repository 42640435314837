import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import Box from "@mui/material/Box";
import CloseIcon from "../../../img/CloseCircle.svg";
import EditIcon from "../../../img/edit.svg";
import TrashIcon from "../../../img/TrashIcon.svg";
import Modal from "@mui/material/Modal";
import SecondryButton from "../../secondryButton";
import PrimaryButton from "../../primaryButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1F1F1F",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

export const InputType = {
  File: 1,
  Text: 2,
};

function ChannelModal({
  inputType,
  open,
  handleClose,
  topic = "",
  desc = "",
  placeholder = "",
  onClick,
  listOfChannel = [],
  removeChannel,
  editChannel,
}) {
  const [textInputValue, setTextInputValue] = useState("");
  const [channelList, setChannelList] = useState([]);

  useEffect(() => {
    const list = [];
    if (listOfChannel.length) {
      listOfChannel.map((ch) => {
        list.push({
          id: ch.id,
          channel_address: ch.channel_address,
          platform: ch.platform,
          isEdit: false,
        });
      });
      setChannelList(list);
    }
  }, []);

  const channelRow = () => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "15px",
          flexDirection: "column",
          marginTop: "20px",
          height: "50%",
          overflowY: "auto",
        }}
      >
        {channelList.map((ch, idx) => {
          return (
            <div className="channelmodal-channel-row-container" key={ch.id}>
              <div className="channelmodal-channel-left-side">
                <span>{`Channel ${idx}:`}</span>
                <input
                  className="channelmodal-prompt-input"
                  type="text"
                  style={{ width: "100%" }}
                  defaultValue={ch.channel_address}
                  placeholder="Channel Url"
                  disabled={!ch.isEdit}
                  onChange={(e) => {
                    const newChList = [...channelList];
                    newChList[idx].channel_address = e.target.value;
                    setChannelList(newChList);
                  }}
                />
              </div>
              {ch.isEdit ? (
                <SecondryButton
                  text="Save"
                  width="82px"
                  onClick={() => {
                    const newChList = [...channelList];
                    newChList[idx].isEdit = false;
                    setChannelList(newChList);
                    editChannel(ch.id, ch.channel_address, ch.platform);
                  }}
                  isDisabled={ch.channel_address === ""}
                />
              ) : (
                <div className="channelmodal-channel-right-side">
                  <img
                    src={TrashIcon}
                    onClick={() => removeChannel(ch.id)}
                    className="input-hover"
                  />
                  <img
                    className="input-hover"
                    src={EditIcon}
                    onClick={() => {
                      const newChList = [...channelList];
                      newChList[idx].isEdit = true;
                      setChannelList(newChList);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="channelmodal-modal-title"
        aria-describedby="channelmodal-modal-description"
        width="400px"
      >
        <Box sx={style}>
          <div className="channelmodal-content-container">
            <div className="channelmodal-header-container">
              <span className="channelmodal-span-topic">
                {topic !== ""
                  ? topic
                  : inputType === InputType.Text
                  ? "Generate Post from URL"
                  : "Generate Post from File"}
              </span>
              <img
                src={CloseIcon}
                onClick={handleClose}
                className="input-hover"
              />
            </div>
            <span className="channelmodal-span-desc">
              {desc
                ? desc
                : inputType === InputType.Text
                ? "If you have an address, paste your URL to generate a post with it."
                : "If you have a document, attach your file to generate a post. (Supported formats:PDF, DOCX)"}
            </span>
            {channelRow()}
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "10px",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div className="channelmodal-prompt">
                <input
                  className="channelmodal-prompt-input"
                  type="text"
                  placeholder={
                    placeholder !== "" ? placeholder : "Enter a Prompt here"
                  }
                  onChange={(e) => {
                    setTextInputValue(e.target.value);
                  }}
                />
              </div>
              <PrimaryButton
                text="Done"
                width="136px"
                height="56px"
                onClick={() => onClick(textInputValue)}
                isDisabled={textInputValue === ""}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ChannelModal;
