import React, { useState } from "react";
import LogoIcon from "../../../img/Logo.svg";
import "./style.css";
import { Level } from "../dashboard";
import TextButton from "../../textButton";
import NextIcon from "../../../img/Next.svg";
import NextDisableIcon from "../../../img/right.svg";
import InfoIcon from "../../../img/info.svg";
import { SelectedType } from "../topicLevel";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

function DashboardFooter({
  level = Level.Topic,
  defaultValue,
  nextDisabled = false,
  nextButtonClick,
  btnText = "Next",
  setPrompt,
  selectedType,
}) {
  const [promptValue, setPromptValue] = useState(defaultValue);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        // margin: "0 94px 32px 94px",
        gap: "32px",
      }}
    >
      <img src={LogoIcon} width="48px" height="48px" />
      {level === Level.Topic && (
        <span style={{ width: "323px" }} className="footer-span-description">
          Content Factory is still in its experimental phase. Chatting with it
          and rating its responses will help improve the experience.
        </span>
      )}
      {level === Level.SocialReady && (
        <span style={{ width: "323px" }} className="footer-span-description">
          Need more detail to Social Ready This post? for example Hashtag or...
          Please Type Prompt
        </span>
      )}
      {level === Level.Translate && (
        <span style={{ width: "323px" }} className="footer-span-description">
          If you need to translate your post please tell us. 
        </span>
      )}

      <div className="footer-prompt-container">
        <div className="footer-prompt">
          <img src={LogoIcon} width="28px" height="28px" />
          <input
            className="footer-prompt-input"
            type="text"
            placeholder="Enter a Prompt here"
            defaultValue={promptValue}
            onChange={(e) => {
              setPromptValue(e.target.value);
              if (setPrompt) setPrompt(e.target.value);
            }}
          />
        </div>
        <TextButton
          text={btnText}
          icon={NextIcon}
          disabledIcon={NextDisableIcon}
          width="100px"
          onClick={() => nextButtonClick(promptValue)}
          isDisabled={nextDisabled}
        />
      </div>
      {level === Level.Topic ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "start",
            gap: "20px",
            marginLeft: "70px",
          }}
        >
          <span className="footer-span-description">
            <strong>Example prompt: </strong>
            {selectedType === SelectedType.None &&
              "Write an engaging Instagram caption to introduce a new skincare product."}
            {selectedType === SelectedType.Url &&
              "Please write a short and engaging summary of the following text for LinkedIn."}
            {selectedType === SelectedType.File &&
              "Please write a blog post about the key findings of this 2024 mobile market report in the following text."}
            {selectedType === SelectedType.Trend &&
              "Write a short article about how Fintech is impacting traditional banking."}
          </span>
          <Tooltip
            title={
              <span className="footer-span-description">
                <strong>Other prompt: </strong>
                {selectedType === SelectedType.None &&
                  "Write a tweet about the positive effects of daily exercise on mental health."}
                {selectedType === SelectedType.Url &&
                  "Write a tweet about the latest tech trends mentioned in the following text."}
                {selectedType === SelectedType.File &&
                  "Write a one-paragraph summary of the following text study for LinkedIn."}
                {selectedType === SelectedType.Trend &&
                  "Write an Instagram post about the reasons why cryptocurrency is popular among young investors."}
              </span>
            }
            placement="right"
          >
            <img src={InfoIcon} />
          </Tooltip>
        </div>
      ) : (
        <span className="footer-span-description">
          Content Factory may display inaccurate or offensive information that
          doesn’t represent Google’s views.
        </span>
      )}
    </div>
  );
}

export default DashboardFooter;
