// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circulare-progress-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  left: 0px;
  top: 0px;
  z-index: 1500;
}

.circulare-progress-container div {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray500);
  opacity: 0.3;
  margin: 0px;
  left: 0px;
  top: 0px;
  filter: blur(8px);
}
`, "",{"version":3,"sources":["webpack://./src/Components/circularIndeterminate/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,SAAS;EACT,QAAQ;EACR,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,0BAA0B;EAC1B,YAAY;EACZ,WAAW;EACX,SAAS;EACT,QAAQ;EAER,iBAAiB;AACnB","sourcesContent":[".circulare-progress-container {\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0px;\n  left: 0px;\n  top: 0px;\n  z-index: 1500;\n}\n\n.circulare-progress-container div {\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: var(--gray500);\n  opacity: 0.3;\n  margin: 0px;\n  left: 0px;\n  top: 0px;\n  -webkit-filter: blur(8px);\n  filter: blur(8px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
