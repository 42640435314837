import React from "react";
import "./style.css";
// import CircularIndeterminate from "../circularIndeterminate";

function TextButton({
  icon,
  disabledIcon,
  text,
  CTAIcon,
  width,
  isDisabled = false,
  onClick,
  style,
  className,
  height,
  // showProgress = false,
}) {
  const localClassName = !isDisabled ? "text-btn-enable" : "text-btn-disable";
  // !showProgress && !isDisabled ? "text-btn-enable" : "text-btn-disable";

  return (
    <div
      onClick={(e) => {
        if (!isDisabled) onClick(e);
      }}
      style={{ ...style, width: width, height: height }}
      className={`${className} ${localClassName}`}
    >
      {/* {showProgress && <CircularIndeterminate size="sm" />} */}
      {/* {text && <span>{showProgress ? "Loading" : text}</span>}
      {icon && !showProgress && <img src={icon} />} */}
      {text && <span>{text}</span>}
      {icon && !isDisabled && <img src={icon} />}
      {disabledIcon && isDisabled && <img src={disabledIcon} />}
      {CTAIcon && <img src={CTAIcon} />}
    </div>
  );
}

export default TextButton;
