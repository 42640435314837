import React, { useEffect, useReducer, useState } from "react";
import GoogleIcon from "../../img/Google.svg";
import HideIcon from "../../img/Hide.svg";
import "./style.css";
import SecondryButton from "../secondryButton";
import TextInput, { InputType } from "../textIput";
import PrimaryButton from "../primaryButton";
import CheckBox from "../checkbox";
import { ComponentEnum } from "../loginSignupFix";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../reducer/userinfo";
import SnackBar from "../snackBar";
import { SnackType } from "../../reducer/snackbar";
import { setSnackMessage } from "../../reducer/snackbar";
import { useGoogleLogin } from "@react-oauth/google";
import CircularIndeterminate from "../circularIndeterminate";
import { api } from "../../api";

const ReducerAction = {
  FirstName: 0,
  LastName: 1,
  UserName: 2,
  Email: 3,
  Password: 4,
  Accept: 5,
};

const initUserSpecification = {
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  password: "",
  accept: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ReducerAction.FirstName:
      return { ...state, firstName: action.payload };
    case ReducerAction.LastName:
      return { ...state, lastName: action.payload };
    case ReducerAction.UserName:
      return { ...state, userName: action.payload };
    case ReducerAction.Email:
      return { ...state, email: action.payload };
    case ReducerAction.Password:
      return { ...state, password: action.payload };
    case ReducerAction.Accept:
      return { ...state, accept: action.payload };
    default:
      return state;
  }
};

function SignUp({ changeState, style }) {
  const [disableBtn, setDisableBtn] = useState(true);
  const [sepecification, dispatch] = useReducer(reducer, initUserSpecification);
  const [showProgress, setShowProgress] = useState(false);
  const [key, setKey] = useState(0);

  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (
      sepecification.firstName !== "" &&
      sepecification.lastName !== "" &&
      sepecification.userName !== "" &&
      sepecification.email !== "" &&
      sepecification.password !== "" &&
      sepecification.accept
    ) {
      setDisableBtn(false);
    } else setDisableBtn(true);
  }, [sepecification]);
  const googleLogin = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      loginByGoogle(credentialResponse.access_token);
    },
    onError: () => {
      console.log("error");
    },
  });

  const loginByGoogle = (access_token) => {
    setShowProgress(true);
    setDisableBtn(true);

    const headers = {
      "Content-Type": "application/json",
    };

    api(
      "get",
      `auth/google?access_token=${access_token}`,
      {},
      headers,
      navigate
    )
      .then((res) => {
        localStorage.setItem("accessToken", res.data.access_token);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            userId: res.data.user.id,
            username: res.data.user.username,
            first_name: res.data.user.first_name,
            last_name: res.data.user.last_name,
            email: res.data.user.email,
          })
        );
        reduxDispatch(
          setUserInfo({
            userId: res.data.user.id,
            username: res.data.user.username,
            first_name: res.data.user.first_name,
            last_name: res.data.user.last_name,
            email: res.data.user.email,
          })
        );
        navigate("/dashboard");
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
        }
      })
      .finally(() => {
        setShowProgress(false);
        setDisableBtn(false);
      });

    // axios
    //   .get(
    //     `https://cfstagingapi.alo.hair/api/auth/google?access_token=${access_token}`,
    //     { headers: headers }
    //   )
    //   .then((res) => {
    //     localStorage.setItem("accessToken", res.data.access_token);
    //     reduxDispatch(
    //       setUserInfo({
    //         userId: res.data.user.id,
    //         username: res.data.user.username,
    //         first_name: res.data.user.first_name,
    //         last_name: res.data.user.last_name,
    //         email: res.data.user.email,
    //       })
    //     );
    //     navigate("/dashboard");
    //   })
    //   .catch((e) => {
    //     if (e?.response?.data?.detail) {
    //       reduxDispatch(
    //         setSnackMessage({
    //           snak_message: e.response.data.detail,
    //           snack_type: SnackType.Danger,
    //         })
    //       );
    //       setKey((k) => k + 1);
    //     }
    //   })
    //   .finally(() => {
    //     setShowProgress(false);
    //     setDisableBtn(false);
    //   });
  };

  const userSignUp = () => {
    setShowProgress(true);
    setDisableBtn(true);

    const body = {
      username: sepecification.userName,
      first_name: sepecification.firstName,
      last_name: sepecification.lastName,
      email: sepecification.email,
      password: sepecification.password,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    api("post", "register", body, headers, navigate)
      .then((res) => {
        localStorage.setItem("accessToken", res.data.access_token);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            userId: res.data.user.id,
            username: res.data.user.username,
            first_name: res.data.user.first_name,
            last_name: res.data.user.last_name,
            email: res.data.user.email,
          })
        );
        reduxDispatch(
          setUserInfo({
            userId: res.data.user.id,
            username: res.data.user.username,
            first_name: res.data.user.first_name,
            last_name: res.data.user.last_name,
            email: res.data.user.email,
          })
        );
        navigate("/dashboard");
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
        }
      })
      .finally(() => {
        setShowProgress(false);
        setDisableBtn(false);
      });

    // axios
    //   .post(
    //     "https://cfstagingapi.alo.hair/api/register",
    //     { ...body },
    //     {
    //       headers: headers,
    //     }
    //   )
    //   .then((res) => {
    //     localStorage.setItem("accessToken", res.data.access_token);
    //     reduxDispatch(
    //       setUserInfo({
    //         userId: res.data.user.id,
    //         username: res.data.user.username,
    //         first_name: res.data.user.first_name,
    //         last_name: res.data.user.last_name,
    //         email: res.data.user.email,
    //       })
    //     );
    //     navigate("/dashboard");
    //   })
    //   .catch((e) => {
    //     if (e?.response?.data?.detail) {
    //       reduxDispatch(
    //         setSnackMessage({
    //           snak_message: e.response.data.detail,
    //           snack_type: SnackType.Danger,
    //         })
    //       );
    //       setKey((k) => k + 1);
    //     }
    //   })
    //   .finally(() => {
    //     setShowProgress(false);
    //     setDisableBtn(false);
    //   });
  };

  return (
    <div style={{ ...style }} className="signup-form-cotainer">
      {showProgress && <CircularIndeterminate />}
      <SnackBar key={key} />

      <div className="signup-form-cotainer-div">
        <div className="signup-form-cotainer-flex">
          <span className="signup-header">Sign up Page</span>
        </div>

        <SecondryButton
          icon={GoogleIcon}
          text="Continue With Google"
          width="70%"
          isDisabled={false}
          style={{ flexDirection: "row-reverse" }}
          onClick={() => googleLogin()}
        />

        <div className="or-container">
          <span>OR</span>
        </div>

        <div className="signup-input-container">
          <TextInput
            type={InputType.TEXT}
            legend="First Name"
            style={{ flex: 1 }}
            onChange={(val) =>
              dispatch({ type: ReducerAction.FirstName, payload: val })
            }
          />
          <TextInput
            type={InputType.TEXT}
            legend="Last Name"
            style={{ flex: 1 }}
            onChange={(val) =>
              dispatch({ type: ReducerAction.LastName, payload: val })
            }
          />
        </div>

        <div className="signup-input-container">
          <TextInput
            type={InputType.TEXT}
            legend="User Name"
            style={{ flex: 1 }}
            onChange={(val) =>
              dispatch({ type: ReducerAction.UserName, payload: val })
            }
          />
          <TextInput
            legend="Email"
            type={InputType.EMAIL}
            style={{ flex: 1 }}
            onChange={(val) =>
              dispatch({ type: ReducerAction.Email, payload: val })
            }
          />
        </div>

        <TextInput
          legend="Password"
          width="70%"
          icon={HideIcon}
          iconText="Hide"
          type={InputType.PASSWORD}
          passValidate={true}
          onChange={(val) =>
            dispatch({ type: ReducerAction.Password, payload: val })
          }
        />

        <div className="signup-form-cotainer-flex" style={{ gap: "20px" }}>
          <span className="sigup-pass-hint">
            Use 8 to 10 characters with a mix of letters, numbers & symbols
          </span>

          <div
            className="signup-form-cotainer-flex"
            style={{ gap: "18px", width: "100%" }}
          >
            <CheckBox
              onChange={(checked) =>
                dispatch({ type: ReducerAction.Accept, payload: checked })
              }
              label={
                <span>
                  Agree to our <a> Terms of </a> use and
                  <a> Privacy Policy </a>
                </span>
              }
            />
          </div>

          <div
            className="signup-form-cotainer-flex"
            style={{ gap: "30px", marginTop: "20px" }}
          >
            <PrimaryButton
              text="Sign up"
              width="200px"
              isDisabled={disableBtn}
              onClick={() => userSignUp()}
              showProgress={showProgress}
            />

            <span className="sigup-pass-hint">
              Already have an account?{" "}
              <span
                className="signup-login-a"
                onClick={() => changeState(ComponentEnum.SignIn)}
              >
                Log in
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
