// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.socail-ready-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  gap: 20px;
  height: 320px;
}

.socail-ready-container-div {
  flex: 1 1;
  height: calc(100% - 50px);
}

.socail-ready-post {
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socail-ready-caption {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 10px;
}

.socail-ready-caption-span {
  color: var(--gray600);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}

.socail-ready-caption-div {
  display: flex;
  resize: none;
  height: 182px;
  padding: 16px 20px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--gray700);
  overflow-y: auto;
  width: -webkit-fill-available;
  background: transparent;
  width: 100%
}
.socail-ready-caption-span {
  color: var(--gray400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
`, "",{"version":3,"sources":["webpack://./src/Components/dashboard/socialReady/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;;EAEvB,WAAW;EACX,SAAS;EACT,aAAa;AACf;;AAEA;EACE,SAAO;EACP,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,SAAS;EACT,kBAAkB;EAClB,gCAAgC;EAChC,gBAAgB;EAChB,6BAA6B;EAC7B,uBAAuB;EACvB;AACF;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B","sourcesContent":[".socail-ready-container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n\n  width: 100%;\n  gap: 20px;\n  height: 320px;\n}\n\n.socail-ready-container-div {\n  flex: 1;\n  height: calc(100% - 50px);\n}\n\n.socail-ready-post {\n  background-color: var(--white);\n  border-radius: 5px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.socail-ready-caption {\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  justify-content: center;\n  gap: 10px;\n}\n\n.socail-ready-caption-span {\n  color: var(--gray600);\n  font-family: Inter;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.36px;\n}\n\n.socail-ready-caption-div {\n  display: flex;\n  resize: none;\n  height: 182px;\n  padding: 16px 20px;\n  align-items: flex-start;\n  gap: 12px;\n  border-radius: 8px;\n  border: 1px solid var(--gray700);\n  overflow-y: auto;\n  width: -webkit-fill-available;\n  background: transparent;\n  width: 100%\n}\n.socail-ready-caption-span {\n  color: var(--gray400);\n  font-family: Inter;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 150% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
