export const colors = {
    Primary: "#479CFF",
    PrimaryTransparent: "#A8C7FA",
    Black: "#000000",
    White: "#FFFFFF",
    Gray800: "#1F1F1F",
    Gray700: "#434242",
    Gray600: "#6C6B6B",
    Gray500: "#898C8F",
    Gray400: "#909498",
    Gray300: "#BDC1C6",
    Gray200: "#DEE2E8",
    Gray100: "#F3F7FD",
    Error: "#F04F4F"
}

