// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-span-description {
  color: var(--gray300);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.1px;
}

.footer-prompt-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.footer-prompt {
  display: flex;
  height: 58px;
  align-items: center;
  justify-content: start;
  padding: 0 19px;
  gap: 10px;
  flex: 1 0;
  border-radius: 28px;
  border: 1px solid var(--gray400);
  background: #131314;
}

.footer-prompt-input {
  background: transparent;
  border: unset;
  height: 100%;
  width: calc(100% - 100px);
}

.footer-prompt-input:focus-visible {
  border: unset;
  outline-offset: unset;
  outline: unset;
}
`, "",{"version":3,"sources":["webpack://./src/Components/dashboard/footer/style.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;EAChC,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,eAAe;EACf,SAAS;EACT,SAAW;EACX,mBAAmB;EACnB,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".footer-span-description {\n  color: var(--gray300);\n  text-align: center;\n  font-family: Inter;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px; /* 166.667% */\n  letter-spacing: 0.1px;\n}\n\n.footer-prompt-container {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  gap: 15px;\n}\n\n.footer-prompt {\n  display: flex;\n  height: 58px;\n  align-items: center;\n  justify-content: start;\n  padding: 0 19px;\n  gap: 10px;\n  flex: 1 0 0;\n  border-radius: 28px;\n  border: 1px solid var(--gray400);\n  background: #131314;\n}\n\n.footer-prompt-input {\n  background: transparent;\n  border: unset;\n  height: 100%;\n  width: calc(100% - 100px);\n}\n\n.footer-prompt-input:focus-visible {\n  border: unset;\n  outline-offset: unset;\n  outline: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
