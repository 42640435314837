import React, { useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import Thumbnail from "../../../img/Thumbnail.svg";
import Edit from "../../../img/blueedit.svg";
import TickCircle from "../../../img/tick-circle.svg";
import { useDispatch } from "react-redux";
import { setSnackMessage, SnackType } from "../../../reducer/snackbar";
import SnackBar from "../../snackBar";
import CircularIndeterminate from "../../circularIndeterminate";
import DashboardFooter from "../footer";
import DashboardHeader from "../header";
import { Level } from "../dashboard";
import TextButton from "../../textButton";
import NextIcon from "../../../img/Next.svg";
import { useNavigate } from "react-router-dom";
import { api } from "../../../api";

function SocialReady({ postId, accessToken, setLevel, isVideo = false }) {
  const [postText, setPostText] = useState({ text: "", fixText: "" });
  const [postImage, setPostImage] = useState("");
  const [key, setKey] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const [edit, setEdit] = useState(false);
  const [editHashtag, setEditHashtag] = useState(false);

  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getPostDetail();
  }, []);

  const getPostDetail = () => {
    setShowProgress(true);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .get(`https://cfstagingapi.alo.hair/api/post/${postId}`, {
    //     headers: headers,
    //   })
    api("get", `post/${postId}`, {}, headers, navigate)
      .then((res) => {
        setPostText({
          text: res?.data?.text ?? "",
          fixText: JSON.stringify(res.data.text),
        });
        if (res.data.image_url) setPostImage(res.data.image_url);
        else if (isVideo && res.data.video_url) setVideoUrl(res.data.video_url);
        else setPostImage(Thumbnail);
        if (res.data.hashtags) setPrompt(res?.data?.hashtags ?? "");
        setShowProgress(false);
        setKey((k) => k + 1);
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e?.response?.data?.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
          setShowProgress(false);
        }
      });
  };

  const updatePostCaption = () => {
    setShowProgress(true);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const body = {
      text: postText.text,
    };

    // axios
    //   .put(
    //     `https://cfstagingapi.alo.hair/api/post/${postId}/text`,
    //     { ...body },
    //     {
    //       headers: headers,
    //     }
    //   )
    api("put", `post/${postId}/text`, body, headers, navigate)
      .then((res) => {
        setEdit(false);
        setShowProgress(false);
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
          setShowProgress(false);
          setEdit(false);
        }
      });
  };

  const updatePostHashtags = () => {
    setShowProgress(true);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const body = {
      hashtag: prompt,
    };

    // axios
    //   .put(
    //     `https://cfstagingapi.alo.hair/api/post/${postId}/hashtag`,
    //     { ...body },
    //     {
    //       headers: headers,
    //     }
    //   )
    api("put", `post/${postId}/hashtag`, body, headers, navigate)
      .then((res) => {
        setEditHashtag(false);
        setShowProgress(false);
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
          setShowProgress(false);
          setEditHashtag(false);
        }
      });
  };

  return (
    <div
      style={{
        width: "90%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <DashboardHeader level={Level.SocialReady} accessToken={accessToken} />
      {showProgress && <CircularIndeterminate />}

      <SnackBar key={key} />
      <div className="socail-ready-container">
        <div className="socail-ready-post socail-ready-container-div">
          {videoUrl !== "" ? (
            <video src={videoUrl} width="100%" height="100%" controls />
          ) : (
            <img key={postImage} src={postImage} width="250px" height="250px" />
          )}
        </div>
        <div className="socail-ready-caption socail-ready-container-div">
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span className="socail-ready-caption-span">Caption</span>
            <TextButton
              width="100px"
              icon={edit ? TickCircle : Edit}
              text={edit ? "Save" : "Edit"}
              onClick={() => {
                if (!edit) setEdit(true);
                else updatePostCaption();
              }}
            />
          </div>

          <textarea
            className="socail-ready-caption-div"
            disabled={!edit}
            onChange={(e) => {
              const post = { ...postText };
              post.text = e.target.value;
              setPostText(post);
            }}
            maxLength={2200}
            key={key}
            value={postText?.text ?? ""}
          />
          <span className="socail-ready-caption-span">
            {postText?.text?.length}/2200
          </span>
        </div>
      </div>

      <div className="socail-ready-caption socail-ready-container-div">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span className="socail-ready-caption-span">Hashtag</span>
          <TextButton
            width="100px"
            icon={editHashtag ? TickCircle : Edit}
            text={editHashtag ? "Save" : "Edit"}
            onClick={() => {
              if (!editHashtag) setEditHashtag(true);
              else updatePostHashtags();
            }}
          />
        </div>

        <textarea
          className="socail-ready-caption-div"
          style={{ height: "100px" }}
          disabled={!editHashtag}
          onChange={(e) => {
            setPrompt(e.target.value);
          }}
          key={key}
          value={prompt}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <TextButton
          text="Next"
          icon={NextIcon}
          width="100px"
          onClick={() => setLevel(Level.Translate)}
        />
      </div>
    </div>
  );
}
export default SocialReady;
