// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span {
  &:hover {
    cursor: default;
  }
}

/* ::-webkit-scrollbar {
  width: 10px;
  background-color: #bdc1c6;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #479cff;
  border-radius: 5px;
} */

:root {
  --primary: #479cff;
  --primaryTransparent: #a8c7fa;
  --black: #000000;
  --white: #ffffff;
  --gray800: #1f1f1f;
  --gray700: #434242;
  --gray600: #6c6b6b;
  --gray500: #898c8f;
  --gray400: #909498;
  --gray300: #bdc1c6;
  --gray200: #dee2e8;
  --gray100: #f3f7fd;
  --error: #f04f4f;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;;;;;;;;;GASG;;AAEH;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\nspan {\n  &:hover {\n    cursor: default;\n  }\n}\n\n/* ::-webkit-scrollbar {\n  width: 10px;\n  background-color: #bdc1c6;\n  border-radius: 5px;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #479cff;\n  border-radius: 5px;\n} */\n\n:root {\n  --primary: #479cff;\n  --primaryTransparent: #a8c7fa;\n  --black: #000000;\n  --white: #ffffff;\n  --gray800: #1f1f1f;\n  --gray700: #434242;\n  --gray600: #6c6b6b;\n  --gray500: #898c8f;\n  --gray400: #909498;\n  --gray300: #bdc1c6;\n  --gray200: #dee2e8;\n  --gray100: #f3f7fd;\n  --error: #f04f4f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
