// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.progress-container span {
  color: var(--gray500);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 200% */
  letter-spacing: 0.5px;
}

.progress-activate {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: var(--primary);
}

.progress-deactive {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: var(--white);
}

.progress-bar {
  height: 8px;
  background-color: var(--white);
  border-radius: 15px;
  width: 100%;
}

.progress-full {
  height: 100%;
  border-radius: 15px;
  background-color: var(--primary);
}
`, "",{"version":3,"sources":["webpack://./src/Components/progress/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;EAC5B,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,gCAAgC;AAClC","sourcesContent":[".progress-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n}\n.progress-container span {\n  color: var(--gray500);\n  text-align: center;\n  font-family: Inter;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 16px; /* 200% */\n  letter-spacing: 0.5px;\n}\n\n.progress-activate {\n  width: 3px;\n  height: 3px;\n  border-radius: 3px;\n  background: var(--primary);\n}\n\n.progress-deactive {\n  width: 3px;\n  height: 3px;\n  border-radius: 3px;\n  background: var(--white);\n}\n\n.progress-bar {\n  height: 8px;\n  background-color: var(--white);\n  border-radius: 15px;\n  width: 100%;\n}\n\n.progress-full {\n  height: 100%;\n  border-radius: 15px;\n  background-color: var(--primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
