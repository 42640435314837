import React, { useEffect, useState } from "react";
import AddSquareIcon from "../../img/WhiteAddSquare.svg";
import InstagramIcon from "../../img/Instagram.svg";
import PostsListIcon from "../../img/PostsList.svg";
import HealthIcon from "../../img/Health.svg";
import FAQIcon from "../../img/FAQ.svg";
import HelpIcon from "../../img/Help.svg";
import SignOutIcon from "../../img/SignOut.svg";
import SettingIcon from "../../img/whiteprofile.svg";
import TextButton from "../textButton";
import "./style.css";
import { NavSideClick } from "../dashboard/dashboard";

function SideNav({ style, onClick, selectedPage }) {
  const [activePage, setActivePage] = useState(selectedPage);

  useEffect(() => {
    setActivePage(selectedPage);
  }, [selectedPage]);

  const onNavClick = (action) => {
    setActivePage(action);
    onClick(action);
  };

  return (
    <div style={{ ...style }} className="side-nav-container">
      <div className="side-nav-top">
        <TextButton
          icon={AddSquareIcon}
          text="New Post"
          width="100%"
          isDisabled={false}
          className="side-nav-btn"
          onClick={() => onNavClick(NavSideClick.NewPost)}
          style={{
            opacity: activePage === NavSideClick.NewPost ? "0.5" : "1",
            flexDirection: "row-reverse",
          }}
        />
        <TextButton
          icon={InstagramIcon}
          text="Social Connect"
          width="100%"
          isDisabled={false}
          className="side-nav-btn"
          onClick={() => onNavClick(NavSideClick.SocialConnect)}
          style={{
            opacity: activePage === NavSideClick.SocialConnect ? "0.5" : "1",
            flexDirection: "row-reverse",
          }}
        />
        <TextButton
          icon={PostsListIcon}
          text="Posts List"
          width="100%"
          isDisabled={false}
          className="side-nav-btn"
          onClick={() => onNavClick(NavSideClick.PostList)}
          style={{
            opacity: activePage === NavSideClick.PostList ? "0.5" : "1",
            flexDirection: "row-reverse",
          }}
        />
        <TextButton
          icon={HealthIcon}
          text="Post Analysis"
          width="100%"
          isDisabled={false}
          className="side-nav-btn"
          onClick={() => onNavClick(NavSideClick.PostAnalyze)}
          style={{
            opacity: activePage === NavSideClick.PostAnalyze ? "0.5" : "1",
            flexDirection: "row-reverse",
          }}
        />
        <TextButton
          icon={FAQIcon}
          text="FAQ"
          width="100%"
          isDisabled={false}
          className="side-nav-btn"
          onClick={() => onNavClick(NavSideClick.FAQ)}
          style={{
            opacity: activePage === NavSideClick.FAQ ? "0.5" : "1",
            flexDirection: "row-reverse",
          }}
        />
        <TextButton
          icon={HelpIcon}
          text="Help"
          width="100%"
          isDisabled={false}
          className="side-nav-btn"
          onClick={() => onNavClick(NavSideClick.Help)}
          style={{
            opacity: activePage === NavSideClick.Help ? "0.5" : "1",
            flexDirection: "row-reverse",
          }}
        />
      </div>
      <TextButton
        icon={SettingIcon}
        text="Settings"
        width="100%"
        isDisabled={false}
        className="side-nav-btn"
        onClick={() => onNavClick(NavSideClick.Setting)}
        style={{
          opacity: activePage === NavSideClick.Setting ? "0.5" : "1",
          flexDirection: "row-reverse",
        }}
      />
      <div className="side-nav-bottom">
        <TextButton
          icon={SignOutIcon}
          text="Sign Out"
          width="100%"
          isDisabled={false}
          className="side-nav-signout"
          onClick={() => onClick(NavSideClick.SignOut)}
          style={{
            flexDirection: "row-reverse",
          }}
        />
      </div>
    </div>
  );
}

export default SideNav;
