import React, { useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import ShareIcon from "../../../img/Share.svg";
import ThumbnailIcon from "../../../img/Thumbnail.svg";
import SnackBar from "../../snackBar";
import { useDispatch } from "react-redux";
import { setSnackMessage, SnackType } from "../../../reducer/snackbar";
import CircularIndeterminate from "../../circularIndeterminate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FilterIcon from "../../../img/filter.png";
import { StyledMenu } from "../header";
import { Tooltip } from "@mui/material";
import { api } from "../../../api";
import { useNavigate } from "react-router-dom";

export const STATE = {
  Publish: 0,
  Schedule: 1,
};

function PostsList({ accessToken, reusePost }) {
  const [postsList, setPostsList] = useState([]);
  const [key, setKey] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [state, setState] = useState(STATE.Publish);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const reduxDispatch = useDispatch();

  useEffect(() => {
    getPostDetail();
  }, []);

  const getPostDetail = () => {
    setShowProgress(true);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .get("https://cfstagingapi.alo.hair/api/users/posts", {
    //     headers: headers,
    //   })
    api("get", "users/posts", {}, headers, navigate)
      .then((res) => {
        setPostsList(res.data.posts);
        setShowProgress(false);
        setState(STATE.Publish);
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
          setShowProgress(false);
        }
      })
      .finally(() => {
        handleClose();
      });
  };

  const getSchedulePostDetail = () => {
    setShowProgress(true);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .get("https://cfstagingapi.alo.hair/api/schedule/posts", {
    //     headers: headers,
    //   })
    api("get", "schedule/posts", {}, headers, navigate)
      .then((res) => {
        setPostsList(res.data);
        setShowProgress(false);
        setState(STATE.Schedule);
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
          setShowProgress(false);
        }
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <div className="posts-list-container">
      {showProgress && <CircularIndeterminate />}
      <SnackBar key={key} />
      <span className="posts-list-container-span">Posts List</span>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <>
          <div
            onClick={() => {
              if (state === STATE.Schedule) getPostDetail();
              else handleClose();
            }}
            className="dashboard-header-publish-menu-div"
          >
            <span className="dashboard-header-publish-menu-span input-hover">
              Publish
            </span>
          </div>
          <div
            onClick={() => {
              if (state === STATE.Publish) getSchedulePostDetail();
              else handleClose();
            }}
            className="dashboard-header-publish-menu-div"
          >
            <span className="dashboard-header-publish-menu-span input-hover">
              Schedule
            </span>
          </div>
        </>
      </StyledMenu>
      <Paper
        sx={{
          width: "100%",
          overflow: "auto",
          backgroundColor: "transparent",
          borderRadius: 0,
        }}
      >
        <TableContainer sx={{ height: `calc(100vh - 300px)` }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#6C6B6B", color: "white" }}>
              <TableRow>
                <TableCell align="center">
                  <span className="posts-list-header-span">POST</span>
                </TableCell>
                <TableCell align="center">
                  <span className="posts-list-header-span">CAPTION</span>
                </TableCell>
                <TableCell align="center">
                  <span className="posts-list-header-span">CREATE DATE</span>
                </TableCell>
                <TableCell align="center">
                  <span className="posts-list-header-span">PUBLISH DATE</span>
                </TableCell>
                <TableCell align="center">
                  <div style={{ display: "flex" }}>
                    <span className="posts-list-header-span">STATE</span>
                    <img
                      src={FilterIcon}
                      onClick={handleClick}
                      className="input-hover"
                    />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <span className="posts-list-header-span">ACTION</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {postsList.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                          flex: "1",
                          gap: "10px",
                        }}
                      >
                        <img
                          width="105px"
                          height="120px"
                          style={{ borderRadius: "8px", flex: 1 }}
                          src={row.image_url || ThumbnailIcon}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "start",
                            flex: "1",
                            gap: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <span className="posts-list-topic">{row.topic}</span>
                          <span className="posts-list-spans">
                            Social: Telegram
                          </span>
                          <span className="posts-list-spans">View: 120K</span>
                        </div>
                      </div>
                    }
                  </TableCell>
                  <TableCell align="justify">
                    {
                      <Tooltip title={row.text} placement="right">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            className="posts-list-caption"
                            style={{ flex: "1" }}
                          >
                            {row.text}
                          </span>
                        </div>
                      </Tooltip>
                    }
                  </TableCell>
                  <TableCell align="justify">
                    {
                      <span className="posts-list-date" style={{ flex: "1" }}>
                        {row.created_at}
                      </span>
                    }
                  </TableCell>
                  <TableCell align="justify">
                    {
                      <span className="posts-list-date" style={{ flex: "1" }}>
                        {row.published_at}
                      </span>
                    }
                  </TableCell>
                  <TableCell align="justify">
                    {
                      <div style={{ textAlign: "center" }}>
                        <span className="posts-list-date" style={{ flex: "1" }}>
                          {state === STATE.Publish ? (
                            "Published"
                          ) : row.published_at !== null ? (
                            "Published"
                          ) : (
                            <span>
                              Pending <br /> {row.next_run_time}
                            </span>
                          )}
                        </span>
                      </div>
                    }
                  </TableCell>
                  <TableCell align="justify">
                    {
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        {/* <div className="posts-list-icon-div-trash">
                          <img src={TrashIcon} />
                        </div>
                        <div className="posts-list-icon-div">
                          <img src={EditIcon} />
                        </div> */}
                        <div
                          className="posts-list-icon-div input-hover"
                          onClick={() => {
                            reusePost(row.id);
                          }}
                        >
                          <img src={ShareIcon} className="input-hover" />
                        </div>
                      </div>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
export default PostsList;
