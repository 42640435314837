import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import Box from "@mui/material/Box";
import CloseIcon from "../../../img/CloseCircle.svg";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import PrimaryButton from "../../primaryButton";
import SecondryButton from "../../secondryButton";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextButton from "../../textButton";
import { SocialEnum } from "../header";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
dayjs.extend(utc);
dayjs.extend(timezone);

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 558,
  bgcolor: "#1F1F1F",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {},

  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#1F1F1F",
    border: "1px solid #434242",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color"]),
    color: "#6C6B6B",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      cursor: "pointer",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
    "&:hover": {
      borderRadius: 4,
      borderColor: "#80bdff",
      cursor: "pointer",
    },
  },
  "& .MuiNativeSelect-icon": {
    color: "#434242",
  },
}));

function ScheduleModal({
  channelList = [],
  publishPost,
  open,
  handleClose,
  schedulePost,
  postsList = [],
  onFinish,
  platform,
}) {
  const [channel, setChannel] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [langs, setLangs] = useState([]);

  useEffect(() => {
    const list = postsList.map((e) => ({ value: e.postId, name: e.lang }));
    setLangs(list);
  });
  const theme = useTheme();
  const [selectedLang, setSelectedLang] = useState([]);

  const handleChangeMulti = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLang(typeof value === "string" ? value.split(",") : value);
  };

  const handleChange = (event) => {
    setChannel(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="scheduleModal-modal-title"
      aria-describedby="scheduleModal-modal-description"
    >
      <Box sx={style} width="558px">
        <div className="scheduleModal-content-container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "20px",
            }}
          >
            <div className="scheduleModal-header-container">
              <span className="scheduleModal-span-topic">
                Schedule to Publish
              </span>
              <img
                src={CloseIcon}
                onClick={handleClose}
                className="input-hover"
              />
            </div>
            <span className="scheduleModal-span-desc">
              If you need to schedule a post, please set the time for
              publishing.
            </span>
          </div>
        </div>

        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <FormControl sx={{ m: 0, marginBottom: 0.5, width: "100%" }}>
            <InputLabel
              id="demo-multiple-name-label"
              style={{
                top: selectedLang.length === 0 ? "-3px" : 0,
                background: "var(--gray800)",
              }}
            >
              select language
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={selectedLang}
              onChange={handleChangeMulti}
              input={<OutlinedInput label="select language" />}
              MenuProps={MenuProps}
              style={{ height: "45px" }}
            >
              {langs.map((lang) => (
                <MenuItem key={lang.value} value={lang.value}>
                  {lang.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {platform === 2 && (
            <FormControl
              sx={{ m: 0, marginBottom: 0.5, width: "100%", marginTop: "10px" }}
            >
              <InputLabel
                id="demo-name-label"
                style={{
                  top: selectedLang.length === 0 ? "-3px" : 0,
                  background: "var(--gray800)",
                }}
              >
                select channel
              </InputLabel>
              <Select
                labelId="demo-name-label"
                id="demo-name"
                // multiple
                value={channel}
                onChange={handleChange}
                input={<OutlinedInput label="select language" />}
                MenuProps={MenuProps}
                style={{ height: "45px" }}
              >
                {channelList.map((ch) => (
                  <MenuItem key={ch.id} value={ch.id}>
                    {ch.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            // <FormControl
            //   sx={{
            //     m: 1,
            //     width: "100%",
            //     borderColor: "white",
            //     margin: "8px 0",
            //   }}
            // >
            //   <InputLabel
            //     style={{
            //       // color: "white",
            //       marginTop: channel === "" ? "-3px" : 0,
            //       backgroundColor: "#1F1F1F",
            //       // padding: "0 5px",
            //     }}
            //     id="demo-simple-select-helper-label"
            //   >
            //     select channel
            //   </InputLabel>
            //   <NativeSelect
            //     id="demo-customized-select-native"
            //     value={channel}
            //     onChange={handleChange}
            //     input={<BootstrapInput />}
            //   >
            //     <option aria-label="None" value="" />
            //     {channelList.map((ch) => {
            //       return (
            //         <option style={{ color: "white" }} value={ch.id}>
            //           {ch.text}
            //         </option>
            //       );
            //     })}
            //   </NativeSelect>
            // </FormControl>
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <DateTimePicker
                ampm={false}
                label="DateTime"
                onChange={(val) => {
                  const y = val.$y;
                  let m = val.$M + 1;
                  m = m < 10 ? `0${m}` : m;
                  const d = val.$D < 10 ? "0" + val.$D : val.$D;
                  const h = val.$H < 10 ? "0" + val.$H : val.$H;
                  const min = val.$m < 10 ? "0" + val.$m : val.$m;

                  const dateTime = `${y}-${m}-${d}T${h}:${min}:00.000Z`;

                  setDateTime(dateTime);
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </ThemeProvider>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "56px",
            width: "100%",
            gap: "12px",
            marginTop: "16px",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <PrimaryButton
              text="Publish now"
              width="136px"
              height="56px"
              onClick={() => {
                for (let i = 0; i < selectedLang.length; i++)
                  publishPost(selectedLang[i], channel);
              }}
              isDisabled={
                platform === SocialEnum.Telegram
                  ? channel === "" || selectedLang.length === 0
                  : selectedLang.length === 0
              }
            />
            <SecondryButton
              text="Schedule"
              width="136px"
              height="56px"
              onClick={() => {
                for (let i = 0; i < selectedLang.length; i++)
                  schedulePost(selectedLang[i], channel, dateTime);
              }}
              isDisabled={
                platform === SocialEnum.Telegram
                  ? dateTime === "" ||
                    channel === "" ||
                    selectedLang.length === 0
                  : dateTime === "" || selectedLang.length === 0
              }
            />
          </div>
          {/* <TextButton
            text="Finish"
            width="136px"
            height="56px"
            isDisabled={false}
            onClick={onFinish}
          /> */}
        </div>
      </Box>
    </Modal>
  );
}

export default ScheduleModal;
