import React from "react";
import "./style.css";
import BackButton from "../../img/left.svg";
import { ComponentEnum } from "../loginSignupFix";
import TextButton from "../textButton";

function CheckEmail({ changeState }) {
  return (
    <div className="checkemail-form-cotainer">
      <div className="checkemail-form-cotainer-div">
        <div className="checkemail-form-cotainer-flex">
          <span className="checkemail-header">Check Email</span>
        </div>

        <div className="checkemail-form-cotainer-flex">
          <span className="checkemail-desc">
            Please check your email inbox and click on the provided link to
            reset your password . If you don’t receive email,
          </span>
          <span className="checkemail-resend"> Click here to resend</span>
        </div>

        <div
          className="checkemail-form-cotainer-flex"
          style={{ gap: "16px", marginTop: "17px" }}
        >
          <TextButton
            text="Back to Login"
            icon={BackButton}
            width="167px"
            isDisabled={false}
            style={{ flexDirection: "row-reverse" }}
            onClick={() => changeState(ComponentEnum.SignIn)}
          />
        </div>
      </div>
    </div>
  );
}

export default CheckEmail;
