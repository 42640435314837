import { createSlice } from "@reduxjs/toolkit";

export const SnackType = {
  Primary: "info",
  Danger: "error",
  Success: "success",
  Warning: "warning",
};

const initialState = {
  key: -1,
  snak_message: "",
  snack_type: SnackType.Danger,
};

export const SnackBarReducer = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setSnackMessage: (state, action) => {
      const { snak_message, snack_type } = action.payload;
      state.key = Math.random();
      state.snak_message = snak_message;
      state.snack_type = snack_type;
    },
  },
});

export const { setSnackMessage } = SnackBarReducer.actions;

export default SnackBarReducer.reducer;
