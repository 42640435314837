// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./chant.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-published-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: end;
}

.post-published-div {
  width: 410px;
  height: 460px;
  /* opacity: 0.3; */
  border-radius: 8px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 50px;
}

.post-publish-tick-container {
  border: 2px solid var(--white);
  border-radius: 50%;
  width: 126px;
  height: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.post-published-span {
  color: var(--white);
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 45px */
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/dashboard/postPublished/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,yDAAkC;EAClC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;EAC5B,iBAAiB;AACnB","sourcesContent":[".post-published-container {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: end;\n}\n\n.post-published-div {\n  width: 410px;\n  height: 460px;\n  /* opacity: 0.3; */\n  border-radius: 8px;\n  background-image: url(./chant.svg);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 30px;\n  margin-bottom: 50px;\n}\n\n.post-publish-tick-container {\n  border: 2px solid var(--white);\n  border-radius: 50%;\n  width: 126px;\n  height: 126px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-left: 10px;\n}\n\n.post-published-span {\n  color: var(--white);\n  text-align: center;\n  font-family: Inter;\n  font-size: 30px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 150%; /* 45px */\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
