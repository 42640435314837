import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import Box from "@mui/material/Box";
import CloseIcon from "../../img/CloseCircle.svg";
import NextIcon from "../../img/Next.svg";
import AddIcon from "../../img/BlueAddSquare.svg";
import DeactiveAddIcon from "../../img/AddSquare.svg";
import Modal from "@mui/material/Modal";
import TextInput, { InputType } from "../textIput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1F1F1F",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

function BasicModal({
  inputType,
  open,
  handleClose,
  topic = "",
  desc = "",
  placeholder = "",
  onClick,
  inputIcon,
  isDisabled = false,
  defaultValue = "",
}) {
  const [textInputValue, setTextInputValue] = useState("");

  useEffect(() => {
    return setTextInputValue("");
  }, []);

  const handleChange = (event) => {
    setTextInputValue(event);
    onClick(event);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-content-container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "20px",
              }}
            >
              <div className="modal-header-container">
                <span className="modal-span-topic">
                  {topic !== ""
                    ? topic
                    : inputType === InputType.TEXT
                    ? "Generate Post from URL"
                    : "Generate Post from File"}
                </span>
                <img
                  src={CloseIcon}
                  onClick={handleClose}
                  className="input-hover"
                />
              </div>
              <span className="modal-span-desc">
                {desc
                  ? desc
                  : inputType === InputType.TEXT
                  ? "If you have an address, paste your URL to generate a post with it."
                  : "If you have a document, attach your file to generate a post. (Supported formats: PDF, DOCX)"}
              </span>
            </div>
            {inputType === InputType.TEXT ? (
              <TextInput
                type={InputType.TEXT}
                disabled={isDisabled}
                placeholder={
                  placeholder !== "" ? placeholder : "Enter a Prompt here"
                }
                onChange={(e) => {
                  setTextInputValue(e);
                }}
                inputIcon={
                  inputIcon && textInputValue !== ""
                    ? inputIcon
                    : inputIcon && textInputValue === ""
                    ? DeactiveAddIcon
                    : NextIcon
                }
                imageClick={() => onClick(textInputValue)}
                width="100%"
                defaultValue={defaultValue}
              />
            ) : (
              <TextInput
                width="100%"
                type={InputType.FILE}
                accept=".pdf, .docs"
                onChange={handleChange}
                inputIcon={AddIcon}
                defaultValue={defaultValue}
              />
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default BasicModal;
