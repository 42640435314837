import axios from "axios";
import { signout } from "../Components/dashboard/dashboard";

//export const basePath = "https://cfstagingapi.alo.hair/api";
export const basePath = "https://stagingapi.content-factory.ai/api";

export function api(
  protocol,
  url,
  body,
  headers,
  navigate,
  form = {},
  timeout = 60000
) {
  return new Promise((resolve, reject) => {
    axios({
      method: protocol,
      url: `${basePath}/${url}`,
      headers: headers,
      data: { ...body },
      timeout: timeout,
      formSerializer: form,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          //   reduxDispatch(
          //     setSnackMessage({
          //       snak_message: "Token is exipre",
          //       snack_type: SnackType.Danger,
          //     })
          //   );
          signout(navigate);
        } else {
          reject(e);
        }
      });
  });
}
