// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../img/ChatContainer.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  width: 100vw;
  /* height: 100vh; */
  height: calc(100vh - 19px);
  display: flex;
  flex-direction: column;
  background-color: var(--black);
}

.dashboard-div-container {
  background-color: var(--black);
  flex: 1 1;
  display: flex;
  height: 100vh;
  margin-top: 70px;
  /* margin: 24px;
  border-radius: 24px;
  border: 1px solid rgba(168, 199, 250, 0.2);
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    lightgray 50% / cover no-repeat; */
}

.dashboard-right-component {
  overflow: auto;
  flex: 5 1;
  margin: 24px;
  border-radius: 24px;
  border: 1px solid rgba(168, 199, 250, 0.2);
  background-size: cover;
  background-repeat: no-repeat;
  transition: width 2s;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 20px;
}

.dashboard-radio-div {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
  /* margin: 20px; */
  width: 100%;
}

.dashboard-radio-div span {
  color: var(--gray100);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/dashboard/dashboard/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;EAC9B,SAAO;EACP,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB;;;;;;;;sCAQoC;AACtC;;AAEA;EACE,cAAc;EACd,SAAO;EACP,YAAY;EACZ,mBAAmB;EACnB,0CAA0C;EAC1C,sBAAsB;EACtB,4BAA4B;EAC5B,oBAAoB;EACpB,yDAAqD;EACrD,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;AACvB","sourcesContent":[".dashboard-container {\n  width: 100vw;\n  /* height: 100vh; */\n  height: calc(100vh - 19px);\n  display: flex;\n  flex-direction: column;\n  background-color: var(--black);\n}\n\n.dashboard-div-container {\n  background-color: var(--black);\n  flex: 1;\n  display: flex;\n  height: 100vh;\n  margin-top: 70px;\n  /* margin: 24px;\n  border-radius: 24px;\n  border: 1px solid rgba(168, 199, 250, 0.2);\n  background: linear-gradient(\n      0deg,\n      rgba(0, 0, 0, 0.8) 0%,\n      rgba(0, 0, 0, 0.8) 100%\n    ),\n    lightgray 50% / cover no-repeat; */\n}\n\n.dashboard-right-component {\n  overflow: auto;\n  flex: 5;\n  margin: 24px;\n  border-radius: 24px;\n  border: 1px solid rgba(168, 199, 250, 0.2);\n  background-size: cover;\n  background-repeat: no-repeat;\n  transition: width 2s;\n  background-image: url(../../../img/ChatContainer.svg);\n  display: flex;\n  flex-direction: column;\n  /* align-items: center; */\n  padding: 20px;\n}\n\n.dashboard-radio-div {\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  gap: 15px;\n  /* margin: 20px; */\n  width: 100%;\n}\n\n.dashboard-radio-div span {\n  color: var(--gray100);\n  font-family: Inter;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
