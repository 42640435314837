import React, { useEffect, useRef, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { setSnackMessage, SnackType } from "../../reducer/snackbar";

function SnackBar() {
  const openSnack = useRef(false);
  const [key, setKey] = useState(0);
  const [message, setMessage] = useState("");
  const [type, setType] = useState(SnackType.Danger);

  const snackInfo = useSelector((state) => state.snackreducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (snackInfo.snak_message !== "") {
      // setOpenSnack(true);
      openSnack.current = true;
      setKey((k) => k + 1);
      setMessage(snackInfo.snak_message);
      setType(snackInfo.snack_type);
    }
  }, [snackInfo.snak_message]);

  // Clean up when component unmounts
  useEffect(() => {
    return () => {
      openSnack.current = false;
      dispatch(
        setSnackMessage({
          snak_message: "",
          snack_type: "",
        })
      );
    };
  }, [dispatch]);

  const handleClose = (event, reason) => {
    // You can remove this condition to ensure the snackbar closes even on clickaway
    // if (reason === "clickaway") return;
    if (openSnack.current) {
      // setOpenSnack(false); // Close the Snackbar
      openSnack.current = false;
      setKey((k) => k + 1);
      dispatch(
        setSnackMessage({
          snak_message: "",
          snack_type: "",
        })
      );
    }
  };

  const vertical = "bottom";
  const horizontal = "center";

  return (
    <div key={key} style={{ display: openSnack.current ? "flex" : "none" }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnack.current ? true : false}
        autoHideDuration={5000}
        onClose={handleClose} // Set handleClose for Snackbar
      >
        <Alert
          key={openSnack}
          onClose={handleClose} // Set handleClose for Alert close action
          severity={type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SnackBar;
