import React, { useEffect, useState } from "react";
import "./style.css";
import TextInput, { InputType } from "../../textIput";
import HideIcon from "../../../img/Hide.svg";
import Edit from "../../../img/blueedit.svg";
import TextButton from "../../textButton";
import SecondryButton from "../../secondryButton";
import PrimaryButton from "../../primaryButton";
import { setSnackMessage, SnackType } from "../../../reducer/snackbar";
import { useDispatch } from "react-redux";
import CircularIndeterminate from "../../circularIndeterminate";
import SnackBar from "../../snackBar";
import { setUserInfo } from "../../../reducer/userinfo";
import { api } from "../../../api";
import { useNavigate } from "react-router-dom";

function Setting({ accessToken, userInfoChanged }) {
  const [isEdit, setIsEdit] = useState(false);
  const [userInfoState, setUserInfoState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
  });
  const [key, setKey] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    console.log(userInfo.first_name);
    setUserInfoState({
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      email: userInfo.email,
      username: userInfo.username,
    });
  }, []);

  const editUserSetting = () => {
    setShowProgress(true);
    const body = {
      first_name: userInfoState.first_name,
      last_name: userInfoState.last_name,
      email: userInfoState.email,
      username: userInfoState.username,
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .put(
    //     `https://cfstagingapi.alo.hair/api/user/${userInfo.userId}`,
    //     { ...body },
    //     {
    //       headers: headers,
    //     }
    //   )
    api("put", `user/${userInfo.userId}`, body, headers, navigate)
      .then((res) => {
        setShowProgress(false);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            userId: res.data.id,
            username: res.data.username,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            email: res.data.email,
          })
        );
        userInfoChanged();
        reduxDispatch(
          setUserInfo({
            userId: res.data.id,
            username: res.data.username,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            email: res.data.email,
          })
        );
      })
      .catch((e) => {
        setShowProgress(false);
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
        }
      });
  };

  return (
    <div style={{ width: "90%", height: "100%" }}>
      <SnackBar key={key} />
      {showProgress && <CircularIndeterminate />}
      <div className="setting-header-container">
        <span className="setting-title-span">Settings</span>
        {!isEdit && (
          <TextButton
            width="100px"
            icon={Edit}
            text="Edit"
            isDisabled={isEdit}
            onClick={() => {
              if (!isEdit) setIsEdit(true);
            }}
          />
        )}
      </div>
      <div className="setting-flex">
        <div>
          <div className="setting-input-container">
            <TextInput
              type={InputType.TEXT}
              legend="First Name"
              style={{ flex: 1 }}
              defaultValue={userInfoState.first_name}
              isDisabled={!isEdit}
              onChange={(val) => {
                const info = { ...userInfoState };
                info.first_name = val;
                setUserInfoState(info);
              }}
            />
            <TextInput
              type={InputType.TEXT}
              legend="Last Name"
              style={{ flex: 1 }}
              defaultValue={userInfoState.last_name}
              isDisabled={!isEdit}
              onChange={(val) => {
                const info = { ...userInfoState };
                info.last_name = val;
                setUserInfoState(info);
              }}
            />
          </div>

          <div className="setting-input-container">
            <TextInput
              type={InputType.TEXT}
              legend="User Name"
              style={{ flex: 1 }}
              defaultValue={userInfoState.username}
              isDisabled={!isEdit}
              onChange={(val) => {
                const info = { ...userInfoState };
                info.username = val;
                setUserInfoState(info);
              }}
            />
            <TextInput
              legend="Email"
              type={InputType.EMAIL}
              style={{ flex: 1 }}
              defaultValue={userInfoState.email}
              isDisabled={!isEdit}
              onChange={(val) => {
                const info = { ...userInfoState };
                info.email = val;
                setUserInfoState(info);
              }}
            />
          </div>

          <TextInput
            legend="Password"
            width="70%"
            icon={HideIcon}
            iconText="Hide"
            type={InputType.PASSWORD}
            passValidate={true}
            isDisabled={!isEdit}
            style={{ marginTop: "30px" }}
            onChange={
              (val) => console.log(val)
              // dispatch({ type: ReducerAction.Password, payload: val })
            }
          />
        </div>
        <div className="setting-btn-container">
          {isEdit && (
            <PrimaryButton
              text="Save"
              width="82px"
              onClick={() => editUserSetting()}
            />
          )}
          {isEdit && (
            <SecondryButton
              text="Exit"
              width="82px"
              onClick={() => {
                setIsEdit(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Setting;
