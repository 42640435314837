import React, { useState } from "react";
import "./style.css";
import TextInput, { InputType } from "../textIput";
import PrimaryButton from "../primaryButton";
import { ComponentEnum } from "../loginSignupFix";

function ResetPassWord({ changeState }) {
  const [email, setEmail] = useState("");
  const [showHint, setShowHint] = useState(false);

  return (
    <div className="resetpass-form-cotainer">
      <div className="resetpass-form-cotainer-div">
        <div className="resetpass-form-cotainer-flex">
          <span className="resetpass-header">Reset Your Password</span>
          <span className="resetpass-h5">
            Enter your email and we'll send you a link to reset your password.
          </span>
        </div>

        <TextInput
          legend="Email address"
          width="70%"
          style={{ marginTop: "48px" }}
          type={InputType.EMAIL}
          onChange={(val) => setEmail(val)}
        />

        <div className="resetpass-form-cotainer-flex">
          {showHint && (
            <span className="resetpass-email-err">
              We can not find your email.
            </span>
          )}
        </div>

        <div
          className="resetpass-form-cotainer-flex"
          style={{ gap: "16px", marginTop: "32px" }}
        >
          <PrimaryButton text="Send" width="167px" isDisabled={email === ""} />

          <span className="resetpass-pass-hint">
            Back to{" "}
            <span
              className="resetpass-login-a"
              onClick={() => changeState(ComponentEnum.SignIn)}
            >
              Login
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default ResetPassWord;
