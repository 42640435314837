import React from "react";
import ProfileIcon from "../../img/Profile.svg";
import LogoIcon from "../../img/Logo.svg";
import TagIcon from "../../img/Tag.svg";
import BurgerIcon from "../../img/Burger.svg";
import "./style.css";
import { colors } from "../../theme";
import { useNavigate } from "react-router-dom";
import { signout } from "../dashboard/dashboard";

function Header({ showBurger, burgerClick, userName, showProfileIcon = true }) {
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: colors.Black }} className="header-cotainer">
      {/* <Menu>
        <SideNav />
      </Menu> */}

      <div className="left-header-side">
        {showBurger && <img src={BurgerIcon} onClick={burgerClick} />}
        <img
          src={LogoIcon}
          width="28px"
          height="28px"
          className="header-logo-icon"
          onClick={() => {
            const info = localStorage.getItem("userInfo");
            if (info) {
              signout(navigate);
            }
            navigate("/");
          }}
        />
        <span
          style={{ color: colors.White }}
          className="input-hover"
          onClick={() => {
            const info = localStorage.getItem("userInfo");
            if (info) {
              signout(navigate);
            }
            navigate("/");
          }}
        >
          Content Factory
        </span>
        <img src={TagIcon} />
      </div>
      <div className="right-header-side">
        <div
          style={{
            // width: "46px",
            height: "46px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {userName && (
            <span className="header-username-span">{`Hello ${userName}`}</span>
          )}
          {showProfileIcon && (
            <img style={{ width: "30px", height: "30px" }} src={ProfileIcon} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
