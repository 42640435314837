import * as React from "react";
import CircularProgress from "@mui/joy/CircularProgress";
import "./style.css";

export default function CircularIndeterminate({ size = "lg" }) {
  return (
    <div className="circulare-progress-container">
      <div></div>
      <CircularProgress variant="solid" size={size} />
    </div>
  );
}
