import React, { useState, useEffect, useReducer } from "react";
import GoogleIcon from "../../img/Google.svg";
import HideIcon from "../../img/Hide.svg";
import "./style.css";
import SecondryButton from "../secondryButton";
import TextInput, { InputType } from "../textIput";
import PrimaryButton from "../primaryButton";
import { ComponentEnum } from "../loginSignupFix";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../reducer/userinfo";
import { setSnackMessage } from "../../reducer/snackbar";
import SnackBar from "../snackBar";
import { SnackType } from "../../reducer/snackbar";
import { useGoogleLogin } from "@react-oauth/google";
import CircularIndeterminate from "../circularIndeterminate";
import { api } from "../../api";

const ReducerAction = {
  Email: 0,
  Password: 1,
};

const initUserSpecification = {
  email: "",
  password: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ReducerAction.Email:
      return { ...state, email: action.payload };
    case ReducerAction.Password:
      return { ...state, password: action.payload };
    default:
      return state;
  }
};

function SignIn({ changeState, setErrorMessage, setResMessage }) {
  const [disableBtn, setDisableBtn] = useState(true);
  const [showProgress, setShowProgress] = useState(false);
  const [key, setKey] = useState(0);

  const [sepecification, dispatch] = useReducer(reducer, initUserSpecification);
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (sepecification.email !== "" && sepecification.password !== "") {
      setDisableBtn(false);
    } else setDisableBtn(true);
  }, [sepecification]);

  const googleLogin = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      loginByGoogle(credentialResponse.access_token);
    },
    onError: () => {
      console.log("error");
    },
  });

  const loginByGoogle = (access_token) => {
    setShowProgress(true);
    setDisableBtn(true);

    const headers = {
      "Content-Type": "application/json",
    };

    // axios
    //   .get(
    //     `https://cfstagingapi.alo.hair/api/auth/google?access_token=${access_token}`,
    //     {
    //       headers: headers,
    //     }
    //   )
    api(
      "get",
      `auth/google?access_token=${access_token}`,
      {},
      headers,
      navigate
    )
      .then((res) => {
        localStorage.setItem("accessToken", res.data.access_token);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            userId: res.data.user.id,
            username: res.data.user.username,
            first_name: res.data.user.first_name,
            last_name: res.data.user.last_name,
            email: res.data.user.email,
          })
        );
        reduxDispatch(
          setUserInfo({
            userId: res.data.user.id,
            username: res.data.user.username,
            first_name: res.data.user.first_name,
            last_name: res.data.user.last_name,
            email: res.data.user.email,
          })
        );
        navigate("/dashboard");
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
        }
      })
      .finally(() => {
        setShowProgress(false);
        setDisableBtn(false);
      });
  };

  const userSignIn = () => {
    setShowProgress(true);
    setDisableBtn(true);

    const body = {
      email: sepecification.email,
      password: sepecification.password,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    // axios
    //   .post(
    //     "https://cfstagingapi.alo.hair/api/token",
    //     { ...body },
    //     { headers: headers }
    //   )
    api("post", "token", body, headers, navigate)
      .then((res) => {
        localStorage.setItem("accessToken", res.data.access_token);
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            userId: res.data.user.id,
            username: res.data.user.username,
            first_name: res.data.user.first_name,
            last_name: res.data.user.last_name,
            email: res.data.user.email,
          })
        );
        reduxDispatch(
          setUserInfo({
            userId: res.data.user.id,
            username: res.data.user.username,
            first_name: res.data.user.first_name,
            last_name: res.data.user.last_name,
            email: res.data.user.email,
          })
        );
        navigate("/dashboard");
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setKey((k) => k + 1);
        }
      })
      .finally(() => {
        setShowProgress(false);
        setDisableBtn(false);
      });
  };

  return (
    <div className="signin-form-cotainer">
      {showProgress && <CircularIndeterminate />}

      <SnackBar key={key} />
      <div className="signin-form-cotainer-div">
        <div className="signin-form-cotainer-flex">
          <span className="signin-header">Sign in Page</span>
        </div>
        <SecondryButton
          icon={GoogleIcon}
          text="Continue With Google"
          width="70%"
          isDisabled={false}
          style={{ flexDirection: "row-reverse" }}
          onClick={() => googleLogin()}
        />

        <div className="or-container">
          <span>OR</span>
        </div>

        <TextInput
          legend="Email address"
          type={InputType.TEXT}
          width="70%"
          style={{ marginBottom: "24px" }}
          onChange={(val) =>
            dispatch({ type: ReducerAction.Email, payload: val })
          }
        />
        <TextInput
          legend="Password"
          width="70%"
          icon={HideIcon}
          iconText="Hide"
          type={InputType.PASSWORD}
          onChange={(val) =>
            dispatch({ type: ReducerAction.Password, payload: val })
          }
        />

        <div
          className="signin-form-cotainer-flex"
          style={{ alignItems: "end" }}
        >
          <span
            className="signin-forget-pass-a"
            onClick={() => changeState(ComponentEnum.ResetPass)}
          >
            Forget your password
          </span>
        </div>

        <div
          className="signin-form-cotainer-flex"
          style={{ gap: "30px", marginTop: "32px" }}
        >
          <PrimaryButton
            text="Sign in"
            width="200px"
            isDisabled={disableBtn}
            onClick={() => userSignIn()}
            style={{ margin: "20px 0" }}
          />

          <span className="signin-pass-hint">
            Don’t have an account?
            <span
              className="signup-login-a"
              onClick={() => changeState(ComponentEnum.SignUp)}
            >
              Sign up
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
