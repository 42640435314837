import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: -1,
  username: "",
  first_name: "",
  last_name: "",
  email: "",
};

export const UserInfoReducer = createSlice({
  name: "userinnfo",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      const { userId, username, first_name, last_name, email } = action.payload;
      state.userId = userId;
      state.username = username;
      state.first_name = first_name;
      state.last_name = last_name;
      state.email = email;
    },
  },
});

export const { setUserInfo } = UserInfoReducer.actions;

export default UserInfoReducer.reducer;
