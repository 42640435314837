import React, { useEffect, useState } from "react";
import TelegramIcon from "../../../img/Telegram.svg";
import ColorInstagramIcon from "../../../img/ColorInstagram.svg";
import LinkdinIcon from "../../../img/Linkdin.svg";
import WhatsAppIcon from "../../../img/WhatsApp.svg";
import "./style.css";
import PrimaryButton from "../../primaryButton";
import NextIcon from "../../../img/WhiteNext.svg";
import BasicModal from "../../modal";
import { InputType } from "../../textIput";
import ChannelModal from "../channelModal";
import ActiveAddIcon from "../../../img/BlueAddSquare.svg";
import SnackBar from "../../snackBar";
import { SnackType } from "../../../reducer/snackbar";
import { signout } from "../dashboard";
import { redirect, useNavigate } from "react-router-dom";
import { setSnackMessage } from "../../../reducer/snackbar";
import { useDispatch, useSelector } from "react-redux";
import CircularIndeterminate from "../../circularIndeterminate";
import { LinkedIn } from "react-linkedin-oauth2";
import { InstagramLogin } from "@amraneze/react-instagram-login";
// import InstagramLogin from "react-instagram-oauth";
import { api } from "../../../api";

const Social = {
  WahtaApp: "WHATSAPP",
  Instagram: "INSTAGRAM",
  Telegram: "TELEGRAM",
  Linkedin: "LINKEDIN",
};

export default function ConnectToSocial({ userInfo }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [basicModalOpen, setBasicModalOpen] = useState(false);
  const [selectedSocial, setSelectedSocial] = useState();
  const [key, setKey] = useState(0);
  const [snackKey, setSnackKey] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  const [instagram_redirect_url, setInstagramRedirexctUrl] = useState("");

  let code = null;
  // https://www.instagram.com/oauth/authorize/?client_id=1652065265631752&redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Finstagram%2Fadd_tester_role%2Foauth%2F&response_type=code&scope=business_basic%2Cbusiness_manage_messages&state={%22ig_app_id%22%3A%221014297996675412%22%2C%22nonce%22%3A%22AEYMtt0MkhqZiJDk%22}&logger_id=571b6733-e7ae-4b91-aaeb-957bc1789aa6
  const redirect_url =
    "https://staging.content-factory.ai/linkedin&state=foobar&scope=openid+profile+w_member_social+email";
  // "https://cfstaging.alo.hair/linkedin&state=foobar&scope=openid+profile+w_member_social+email";
  // "http://localhost:3000/linkedin&state=foobar&scope=openid+profile+w_member_social+email";

  // const instagram_redirect_url =
  // "https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1684635465653809&redirect_uri=https://staging.content-factory.ai/&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish";
  // "https://stagingapi.content-factory.ai/api/instagram/oauth-link?redirect_uri=https://staging.content-factory.ai";

  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const linkedinCode = useSelector((state) => state.linkedinReducer);

  const [socialList, setSocialList] = useState([
    {
      id: Social.WahtaApp,
      name: "WhatsApp",
      hasChannel: false,
      channelList: [],
    },
    {
      id: Social.Instagram,
      name: "Instagram",
      hasChannel: false,
      channelList: [],
    },
    {
      id: Social.Telegram,
      name: "Telegram",
      hasChannel: false,
      channelList: [],
    },
    {
      id: Social.Linkedin,
      name: "LinkedIn",
      hasChannel: false,
      channelList: [],
    },
  ]);

  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    console.log(linkedinCode);
  }, [linkedinCode]);

  useEffect(() => {
    getUserChannels();
    getInstagramLink();
  }, []);

  function getInstagramLink() {
    // setShowProgress(true);
    api(
      "get",
      `instagram/oauth-link?redirect_uri=https://staging.content-factory.ai`,
      navigate
    )
      .then((response) => {
        // console.log(response);
        setInstagramRedirexctUrl(response?.data ?? "");
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
        }
      })
      .finally(() => {
        // setShowProgress(false);
      });
  }

  const openModal = (socialId) => {
    setModalOpen(true);
    setSelectedSocial(socialId);
    setKey((k) => k + 1);
  };

  const openBasicModal = (socialId) => {
    setBasicModalOpen(true);
    setSelectedSocial(socialId);
    setKey((k) => k + 1);
  };

  const getUserChannels = () => {
    setShowProgress(true);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .get("https://cfstagingapi.alo.hair/api/channels/own", {
    //     headers: headers,
    //   })
    api("get", "channels/own", {}, headers, navigate)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          console.log("userChannels:", res?.data);
          const channels = [];
          res.data.map((r) => {
            channels.push({
              id: r.id,
              platform: r.platform,
              channel_address: r.channel_address,
            });
          });
          const userSocialList = [
            {
              id: Social.WahtaApp,
              name: "WhatsApp",
              hasChannel: false,
              channelList: [],
            },
            {
              id: Social.Instagram,
              name: "Instagram",
              hasChannel: false,
              channelList: [],
            },
            {
              id: Social.Telegram,
              name: "Telegram",
              hasChannel: false,
              channelList: [],
            },
            {
              id: Social.Linkedin,
              name: "LinkedIn",
              hasChannel: false,
              channelList: [],
            },
          ];
          userSocialList.map((s, index) => {
            const list = channels.filter((ch) => ch.platform === s.id);
            if (list.length) {
              userSocialList[index].hasChannel = true;
              userSocialList[index].channelList = list;
            }
          });
          setSocialList(userSocialList);
          setKey((k) => k + 1);
        } else {
          const userSocialList = [...socialList];
          userSocialList.map((s, index) => {
            userSocialList[index].hasChannel = false;
            userSocialList[index].channelList = [];
          });
          setSocialList(userSocialList);
          setKey((k) => k + 1);
          setModalOpen(false);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setSnackKey((k) => k + 1);
        }
      })
      .finally(() => {
        setShowProgress(false);
      });
  };

  const checkUserMemberChannel = (channelname) => {
    return new Promise((resolve, reject) => {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      api("get", `channels/check/${channelname}`, {}, headers, navigate)
        .then((res) => {
          if (res.data.ok) resolve("OK");
          else reject();
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const addNewChannel = (urlAddress) => {
    setShowProgress(true);
    checkUserMemberChannel(urlAddress)
      .then(() => {
        const body = {
          user_id: userInfo.userId,
          platform: "TELEGRAM",
          channel_address: urlAddress,
          username: "",
          password: "",
          api_token: "",
        };

        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        api("post", "channels/create", body, headers, navigate)
          .then(() => {
            getUserChannels();
            setBasicModalOpen(false);
          })
          .catch((e) => {
            if (e?.response?.data?.detail) {
              reduxDispatch(
                setSnackMessage({
                  snak_message: e.response.data.detail,
                  snack_type: SnackType.Danger,
                })
              );
              setSnackKey((k) => k + 1);
            }
          })
          .finally(() => {
            setShowProgress(false);
          });
      })
      .catch(() => {
        reduxDispatch(
          setSnackMessage({
            snak_message: "Add Bot to your channel",
            snack_type: SnackType.Danger,
          })
        );
        setSnackKey((k) => k + 1);
        setShowProgress(false);
      });
  };

  const removeChannel = (id) => {
    console.log(id);
    setShowProgress(true);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    api("delete", `channels/${id}`, {}, headers, navigate)
      .then(() => {
        getUserChannels();
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setSnackKey((k) => k + 1);
        }
      })
      .finally(() => {
        setShowProgress(false);
      });
  };

  const editChannel = (id, channel_url, platform) => {
    setShowProgress(true);

    const body = {
      platform: platform,
      channel_address: channel_url,
      username: "",
      password: "",
      api_token: "",
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .put(
    //     `https://cfstagingapi.alo.hair/api/channels/${id}`,
    //     { ...body },
    //     {
    //       headers: headers,
    //     }
    //   )
    api("put", `channels/${id}`, body, headers, navigate)
      .then(() => {
        getUserChannels();
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setSnackKey((k) => k + 1);
        }
      })
      .finally(() => {
        setShowProgress(false);
      });
  };

  const setUserLikedinCode = (code) => {
    setShowProgress(true);

    const body = {
      // code: code,
      // redirect_uri: redirect_url,
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .post(
    //     `https://cfstagingapi.alo.hair/api/linkedin/user-info?code=${code}&redirect_uri=${redirect_url}`,
    //     { ...body },
    //     {
    //       headers: headers,
    //     }
    //   )
    api(
      "post",
      `linkedin/user-info?code=${code}&redirect_uri=${redirect_url}`,
      body,
      headers,
      navigate
    )
      .then(() => {
        getUserChannels();
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setSnackKey((k) => k + 1);
          code = null;
        }
      })
      .finally(() => {
        setShowProgress(false);
      });
  };

  const handleLinkedInSuccess = (data) => {
    if (code === null) {
      code = data.code;
      setUserLikedinCode(data.code);
    }
  };

  const handleLinkedInFailure = (error) => {
    console.log(error.errorMessage);
  };

  const setUserInstagramCode = (code) => {
    setShowProgress(true);

    const body = {
      // code: code,
      // redirect_uri: redirect_url,
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    // axios
    //   .post(
    //     `https://cfstagingapi.alo.hair/api/linkedin/user-info?code=${code}&redirect_uri=${redirect_url}`,
    //     { ...body },
    //     {
    //       headers: headers,
    //     }
    //   )
    api(
      "post",
      `instagram/access-token?redirect_uri=https://staging.content-factory.ai/&code=${code}#_`,
      body,
      headers,
      navigate
    )
      .then(() => {
        getUserChannels();
      })
      .catch((e) => {
        if (e?.response?.data?.detail) {
          reduxDispatch(
            setSnackMessage({
              snak_message: e.response.data.detail,
              snack_type: SnackType.Danger,
            })
          );
          setSnackKey((k) => k + 1);
          code = null;
        }
      })
      .finally(() => {
        setShowProgress(false);
      });
  };

  function successResponseInstagram(response) {
    console.log("success ", response);
    setUserInstagramCode(response ?? "");
  }
  const failResponseInstagram = (response) => console.log("failed ", response);

  const authHandler = (err, data) => {
    console.log(err, data);
  };

  return (
    <div style={{ width: "90%", height: "100%" }}>
      <div className="social-container">
        {showProgress && <CircularIndeterminate />}
        <SnackBar key={snackKey} />

        <BasicModal
          open={basicModalOpen}
          handleClose={() => setBasicModalOpen(false)}
          inputIcon={ActiveAddIcon}
          inputType={InputType.TEXT}
          placeholder="Add your channel ID"
          topic={`Connect ${
            selectedSocial === Social.Telegram
              ? "Telegram"
              : selectedSocial === Social.Instagram
              ? "Instagram"
              : selectedSocial === Social.WahtaApp
              ? "WhatsApp"
              : "LinkedIn"
          }`}
          desc={
            <span>
              First, please join to Bot:
              <br />{" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <a href="https://t.me/cf_squad_bot">
                  https://t.me/cf_squad_bot
                </a>{" "}
              </div>
            </span>
          }
          onClick={addNewChannel}
        />

        <ChannelModal
          open={modalOpen}
          listOfChannel={
            socialList.filter((ch) => ch.id === selectedSocial)[0]?.channelList
          }
          removeChannel={removeChannel}
          editChannel={editChannel}
          key={key}
          handleClose={() => setModalOpen(false)}
          inputType={InputType.TEXT}
          placeholder="Add your channel ID"
          topic={`Connect ${
            selectedSocial === Social.Telegram
              ? "Telegram"
              : selectedSocial === Social.Instagram
              ? "Instagram"
              : selectedSocial === Social.WahtaApp
              ? "WhatsApp"
              : "LinkedIn"
          }`}
          desc={
            <span>
              first, please join to Bot:
              <br />{" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <a href="https://t.me/cf_squad_bot">
                  https://t.me/cf_squad_bot
                </a>{" "}
              </div>
            </span>
          }
          onClick={addNewChannel}
        />

        <span className="social-header">Social Connect</span>
        {socialList.filter((s) => s.hasChannel).length > 0 && (
          <span className="social-connected-span">Connected</span>
        )}

        {/* Connected Channels */}
        {socialList.filter((s) => s.hasChannel).length > 0 && (
          <div
            className="social-div"
            style={{ borderBottom: "1px solid var(--primary)" }}
          >
            <div className="social-first-row">
              {socialList
                .filter((s) => s.hasChannel)
                .map((sc) => {
                  return (
                    <div key={`connected-channels-${sc.id}`}>
                      <img
                        width="44px"
                        height="44px"
                        src={
                          sc.id === Social.WahtaApp
                            ? WhatsAppIcon
                            : sc.id === Social.Instagram
                            ? ColorInstagramIcon
                            : sc.id === Social.Telegram
                            ? TelegramIcon
                            : LinkdinIcon
                        }
                      />
                      <PrimaryButton
                        text={
                          sc.id === Social.Telegram
                            ? `Detail ${sc.name}`
                            : `Delete ${sc.name}`
                        }
                        width="280px"
                        onClick={() => {
                          if (sc.id === Social.Telegram) openModal(sc.id);
                          else removeChannel(sc.channelList[0]?.id);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {/* Not Connected Channels */}
        {socialList.filter((s) => !s.hasChannel).length > 0 && (
          <div className="social-div">
            <div className="social-first-row">
              {socialList
                .filter((s) => !s.hasChannel)
                .map((sc) => {
                  if (sc.id === Social.Instagram && !instagram_redirect_url)
                    return <></>;
                  return (
                    <div key={`not-connected-channels-${sc.id}`}>
                      <img
                        alt=""
                        width="44px"
                        height="44px"
                        src={
                          sc.id === Social.WahtaApp
                            ? WhatsAppIcon
                            : sc.id === Social.Linkedin
                            ? LinkdinIcon
                            : sc.id === Social.Telegram
                            ? TelegramIcon
                            : ColorInstagramIcon
                        }
                      />
                      {sc.id === Social.Linkedin ? (
                        <LinkedIn
                          id="linkedInLogin"
                          clientId="785k95gpgsx7co"
                          onFailure={handleLinkedInFailure}
                          onSuccess={handleLinkedInSuccess}
                          redirectUri={redirect_url}
                          style={{ display: "none" }}
                          // className="input-hover"
                        >
                          <span>Connect LinkedIn</span>
                          <img className="btn-linkedin-img" src={NextIcon} />
                        </LinkedIn>
                      ) : sc.id === Social.Instagram ? (
                        <div
                          style={{
                            width: "280px",
                            height: "44px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "start",
                          }}
                        >
                          <div
                            style={{ width: "100%", height: "100%" }}
                            className="test"
                          >
                            <InstagramLogin
                              authCallback={authHandler}
                              clientId="1652065265631752"
                              redirectUri={instagram_redirect_url}
                              onSuccess={successResponseInstagram}
                              onFailure={failResponseInstagram}
                            >
                              <span>Connect {sc.name}</span>
                              <img src={NextIcon} alt="" />
                            </InstagramLogin>
                          </div>
                        </div>
                      ) : (
                        // <div
                        //   style={{
                        //     width: "280px",
                        //     height: "44px",
                        //     display: "flex",
                        //     flexDirection: "row",
                        //     justifyContent: "center",
                        //     alignItems: "start",
                        //   }}
                        // >
                        //   <div
                        //     style={{
                        //       width: "100%",
                        //       height: "100%",
                        //       flexDirection: "row",
                        //       background: "var(--primary)",
                        //       borderRadius: "8px",
                        //     }}
                        //     className="test"
                        //   >
                        //     <InstagramLogin
                        //       authCallback={authHandler}
                        //       appId={1684635465653809}
                        //       appSecret={"4b70e6963121d81f9fcf2114b7258b5c"}
                        //       redirectUri={instagram_redirect_url}
                        //       onSuccess={successResponseInstagram}
                        //       onFailure={failResponseInstagram}
                        //       className="test-svg"
                        //       buttonTheme="simple"
                        //     ></InstagramLogin>
                        //     <span>Connect {sc.name}</span>
                        //     <img src={NextIcon} />
                        //   </div>
                        // </div>
                        <PrimaryButton
                          text={`Connect ${sc.name}`}
                          width="280px"
                          icon={NextIcon}
                          onClick={(e) => {
                            openBasicModal(sc.id);
                          }}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
